const BlockyComingSoon = () => {
  return (
    <div className="xl:absolute shadow-lg top-[30%] right-[10%] h-auto rounded-[48px] px-[20px] py-[45px] bg-white z-10 shadow-[0px 34px 44px 0px #0000001C;] bg-[#f5f5f5]">
      <div className="pointer-events-none opacity-60 grayscale">
        <div className="flex justify-center gap-[6px] mb-6">
          <div className="time-box w-[70px] h-[50px] xl:h-[70px] xl:w-[100px] bg-[#DCAFFA] flex flex-col text-base xl:text-[22px]">
            <span>01</span>
            <span>Days</span>
          </div>
          <div className="time-box w-[70px] h-[50px] xl:h-[70px] xl:w-[100px] bg-[#A1D6FB] flex flex-col text-base xl:text-[22px]">
            <span>12</span>
            <span>Hours</span>
          </div>
          <div className="time-box w-[70px] h-[50px] xl:h-[70px] xl:w-[100px] bg-[#AAFCE5] flex flex-col text-base xl:text-[22px]">
            <span>00</span>
            <span>Mins</span>
          </div>
          <div className="time-box w-[70px] h-[50px] xl:h-[70px] xl:w-[100px] bg-[#FCEE9A] flex flex-col text-base xl:text-[22px]">
            <span>08</span>
            <span>Secs</span>
          </div>
        </div>
        <div className="flex justify-center items-center mb-4 font-IBM-sans font-bold text-[18px] xl:text-[24px]">
          Presale Phase 3
        </div>
        <div className="mb-6 process">
          <div className="bar text-[14px]">80%</div>
        </div>
        <div className="mb-[30px] h-100">
          <div className="flex justify-between items-center mb-4">
            <div className="font-IBM-sans font-bold text-[14px] xl:text-[20px] mr-2">
              SOLD:
            </div>
            <div className="">
              <span className="font-IBM-sans font-semibold text-[12px] xl:text-[16px]">
                13,445,175,289
              </span>
              <span className="font-IBM-sans text-[12px] xl:text-[16px]">
                /15,000,000 $BLOCKY
              </span>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="font-IBM-sans font-bold text-[14px] xl:text-[20px]">
              RAISED
            </div>
            <div className="flex gap-2">
              <span className="font-IBM-sans font-semibold text-[12px] xl:text-[16px]">
                $4,800,000
              </span>
              <span className="font-IBM-sans text-[12px] xl:text-[16px]">
                $6,000,000
              </span>
            </div>
          </div>
        </div>

        <div className="mb-[30px]">
          <form className="flex relative">
            <input
              placeholder="Bonus code here..."
              className=" h-[48px] w-[77%] p-5 rounded-[4px] bg-[#DFDFDF] relative z-10"
            />
            <button className="w-[110px] text-white bg-linear-purple rounded-[4px] absolute top-0 right-0 h-[48px] z-20">
              Apply
            </button>
          </form>
        </div>

        <div className="mb-4 max-w-[365px] rounded-[50px] font-dongo-regular px-16 pt-4 pb-1.5 text-[18px] bg-[#F0B90B] m-auto uppercase">
          1 $blocky = $0.0005
        </div>
        <div className="mb-4 text-base font-IBM-sans text-center">
          you can customize below
        </div>

        <div className="flex gap-2 xl:mb-[32px]">
          <button className="w-[50%] h-[48px] w-[144px] rounded-[8px] border border-[#EEEEEE] text-start pl-5 text-[14px] font-medium text-[#DFDFDF]">
            Pay ETH: 0
          </button>
          <button className="w-[50%] h-[48px] w-[144px] rounded-[8px] border border-[#EEEEEE] text-start pl-5 text-[14px] font-medium text-[#DFDFDF]">
            Receive $Blocky
          </button>
        </div>

        <div className="absolute bottom-[-70px] lg:bottom-[-110px] left-1/2 transform -translate-x-1/2 w-[220px] h-[55px] xl:w-[296px] xl:h-[75px] rounded-[70px] bg-btn-connect-wallet flex justify-center items-center gap-5">
          <img src="/svg/wallet.svg" alt="wallet" />
          <span className="font-dongo-regular text-white text-[20px] xl:mb-[-16px] mb-[-10px]">
            Connect Wallet
          </span>
        </div>
      </div>
      <div className="absolute  top-[70%] xl:top-[62%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 max-w-[212px] lg:max-w-[500px] text-center">
        <div className="relative text-center">
          <p className="font-dongo-regular text-coming text-[54px] lg:text-[84px] -translate-y-1/3 mb-[-50px] xl:leading-[100px]">
            COMING SOON!
          </p>
          <p className="font-dongo-regular text-coming-before text-[54px] lg:text-[84px] absolute top-0 left-0 z-20 -translate-y-1/3  xl:leading-[100px]">
            COMING SOON!
          </p>
        </div>
      </div>
    </div>
  );
};
export default BlockyComingSoon;
