export function MiniGameDivider({ isBottom = false }) {
  if (isBottom) {
    return (
      <div className="relative">
        <div className="bg-white h-[150px] absolute w-full bottom-0 blur-lg"></div>
      </div>
    );
  }

  return (
    <div className="relative">
      <div className="bg-white h-[150px] absolute w-full bottom-0 blur-lg translate-y-1/2"></div>
    </div>
  );
}
