import MiniGame2 from '../../assets/images/mini-game-2.png';
import MiniGame3 from '../../assets/images/mini-game-3.png';
import MiniGame4 from '../../assets/images/mini-game-4.png';
import MiniGame5 from '../../assets/images/mini-game-5.png';

export function MiniGameSection4() {
  return (
    <div className={`p-4 md:px-6 pb-28 md:pt-10 flex flex-col items-center relative`}>
      <div className="relative text-[25px] md:text-[64px] text-center">
        <div className="font-dongo-regular bg-gradient-1">
          Get more points by sharing with friends
        </div>
        <div className="font-dongo-outline text-[#221F50] absolute top-0">
          Get more points by sharing with friends
        </div>
      </div>

      <p className="text-xs md:text-2xl text-center">
        Top coin earners participate in the
        <br />
        $BLOCKY airdrop
      </p>

      <div className="flex justify-center overflow-hidden">
        <img
          src={MiniGame2}
          alt=""
          className="w-2/3 md:w-1/3 translate-x-14 md:translate-x-28 translate-y-10"
        />
        <img src={MiniGame3} alt="" className="w-2/3 md:w-1/3" />
        <img
          src={MiniGame4}
          alt=""
          className="w-2/3 md:w-1/3 -translate-x-14 md:-translate-x-28 translate-y-10"
        />
      </div>

      <div className={`bg-[url(${MiniGame5})] bg-cover bg-center inset-0 absolute blur-xl -z-10`} />
    </div>
  );
}
