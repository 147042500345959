import MiniGame6 from '../../assets/images/mini-game-6.png';
import MiniGame7 from '../../assets/images/mini-game-7.png';

export function MiniGameSection3() {
  return (
    <div className={`p-4 md:px-6 md:py-10 flex justify-center relative overflow-x-hidden`}>
      <img src={MiniGame7} alt="" className="w-[125vw] md:w-fit max-w-none" />
      <div className={`bg-[url(${MiniGame6})] bg-cover bg-center inset-0 absolute blur-xl -z-10`} />
    </div>
  );
}
