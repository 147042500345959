export const question = [
  {
    type: "General Questions",
    question: [
      {
        ask: "What is $Blocky?",
        answer:
          "$BLOCKY is our native utility token used within the Gamefi ecosystem. It enables users to acquire Blockchain Doggs NFTs, participate in play-to-earn games, and access exclusive perks and rewards.",
      },
      {
        ask: "What are Blockchain Doggs?",
        answer:
          "Blockchain Doggs are 10,000 uniquely crafted NFTs on the Solana network. They are integral to our gaming ecosystem, offering exclusive benefits and the ability to earn passive income through various in-game activities.",
      },
      {
        ask: "Why NFTs and $Blocky token?",
        answer:
          "Combining stunning NFTs for PFP collectibles with the memeability and deep liquidity of a fungible token, the $BLOCKY token has a significant opportunity for substantial demand and price increase. Together, they combine their strengths in marketing, advertising, adoption, utilities, earning opportunities, and multiple ways of increasing $BLOCKY token value, all under one brand. This could mark the standout success of the 2024/2025 bull run.",
      },
    ],
  },
  {
    type: "Token and NFT Acquisition",
    question: [
      {
        ask: "How can I purchase $Blocky tokens?",
        answer:
          "$Blocky tokens can be purchased during the presale event. Details about the presale will be announced on our official website and social media channels.",
      },
      {
        ask: "How do I acquire Blockchain Doggs NFTs?",
        answer:
          "Blockchain Doggs NFTs can be acquired using $BLOCKY tokens. The minting process and further instructions will be provided on our platform once the presale is completed.",
      },
      {
        ask: "What benefits do I get from owning Blockchain Doggs NFTs?",
        answer:
          "Owners of Blockchain Doggs NFTs can participate in exclusive play-to-earn games, unlock special rewards, and generate passive income through staking and other in-game activities. Additionally, there will be surprise rewards and airdrops from revenue shares from Investdex.io listing fees.",
      },
    ],
  },
  {
    type: "Gameplay and Earnings",
    question: [
      {
        ask: "What types of games can I play with Blockchain Doggs?",
        answer:
          "Our platform offers a variety of mobile mini-games and adventures designed for the play-to-earn model. These games allow you to earn crypto rewards and enhance your Blockchain Doggs' abilities.",
      },
      {
        ask: "How do I earn passive income with Blockchain Doggs?",
        answer:
          "Passive income can be earned by staking your Blockchain Doggs NFTs or participating in certain in-game activities that generate ongoing rewards. Additionally, there will be surprise rewards and airdrops from Investdex.io ",
      },
      {
        ask: "Are there any other ways to earn with Blocky tokens?",
        answer:
          "Yes, besides playing games, you can earn $BLOCKY tokens through various community events, competitions, and by contributing to the ecosystem’s growth.",
      },
    ],
  },
  {
    type: "Technical and Security",
    question: [
      {
        ask: "On which blockchain are Blocky and Blockchain Doggs built?",
        answer:
          "Both $BLOCKY tokens and Blockchain Doggs NFTs are built on the Solana blockchain, chosen for its high speed and low transaction costs.",
      },
      {
        ask: "What wallets are supported for storing Blocky tokens and Blockchain Doggs NFTs?",
        answer:
          "$BLOCKY tokens and Blockchain Doggs NFTs can be stored in any Solana-compatible wallet, such as Phantom, Sollet, and Solflare. tokens and Blockchain Doggs NFTs can be stored in any Solana-compatible wallet, such as Phantom, Sollet, and Solflare.",
      },
    ],
  },
  {
    type: "Community and Support",
    question: [
      {
        ask: "How can I stay updated with the latest news and updates?",
        answer:
          "Follow our official social media channels, join our Telegram community, and subscribe to our newsletter for the latest updates, news, and announcements.",
      },
    ],
  },
  {
    type: "Additional Questions",
    question: [
      {
        ask: "Who is the lead developer?",
        answer:
          "The lead game designer and developer is from the professional development studio responsible for creating InvestDex.io.",
      },
      {
        ask: "When will the full game launch?",
        answer:
          "Our first game, Tap2Earn, will launch soon, before the listing, creating instant demand for $BLOCKY tokens. More games are expected to roll out in Q4 2024.",
      },
      {
        ask: "Why presale?",
        answer:
          "A presale is a standard strategy aimed at building genuine community engagement and interest, acquiring a specific number of unique token holders for Tier 1 CEX listings, and raising funds for development, listing fees, and marketing costs. Additionally, presale success plays a crucial role in shaping the terms of signed VCs.",
      },
      {
        ask: "Have you signed any VCs?",
        answer:
          "We plan to secure VC investments for both token and equity after the presale, ensuring we engage institutional investors at a suitable valuation. We are currently in discussions with multiple VCs and seeking partners who can add significant value to the $BLOCKY community.",
      },
    ],
  },
];
export const token_Allocation = [
  {
    title: "Liquidity:",
    statistics: "15% (75,000,000,000 BLOCKY)",
    subTitle:
      "Tokens allocated to provide liquidity on exchanges, ensuring smooth trading and stability of the token price.",
  },
  {
    title: "Team:",
    statistics: "5% (25,000,000,000 BLOCKY)",
    subTitle:
      "Tokens allocated to the core team, with a vesting period to align long-term interests and dedication.",
  },
  {
    title: "Listing:",
    statistics: "5% (25,000,000,000 BLOCKY)",
    subTitle:
      "Tokens allocated for exchange listings, providing liquidity and facilitating trading on major exchanges.",
  },
  {
    title: "Treasury:",
    statistics: "10% (50,000,000,000 BLOCKY)",
    subTitle:
      "Tokens reserved to support long-term project development, partnerships, and future innovations.",
  },
  {
    title: "Marketing:",
    statistics: "10% (50,000,000,000 BLOCKY)",
    subTitle:
      "Tokens reserved for marketing campaigns, community events, and driving participation in the ecosystem.",
  },
  {
    title: "Community Rewards & Airdrop:",
    statistics: "35% (175,000,000,000 BLOCKY)",
    subTitle:
      "Tokens allocated to reward the community through airdrops, incentivizing active engagement and participation.",
  },
];
export const roadmap = [
  {
    phase: "Phase 1: GameFi Launch Preparation",
    title: "Community Building",
    description: [
      {
        paragraph:
          "Launch social media channels and the official website, engage with potential users through AMAs, contests, and giveaways.",
      },
      {
        paragraph:
          "Token Presale: Announce the $Blocky token presale, providing detailed information on participation and the benefits of early adoption.",
      },
    ],
  },
  {
    phase: "Phase 2: GameFi Mini-Game Launch",
    title: "Mobile Mini-Game",
    description: [
      {
        paragraph:
          "Release the first mobile mini-game on Telegram, integrating tap-to-earn mechanics for crypto rewards.",
      },
      {
        paragraph:
          "In-Game Economy: Execute a $Blocky token airdrop to players, announce NFT integration, and implement staking and earning mechanisms.",
      },
      {
        paragraph:
          "Marketing Campaign: Launch targeted marketing campaigns, partner with influencers, and promote the mini-game to drive user engagement.",
      },
      {
        paragraph:
          "Strategic Partnerships: Collaborate with other mini-game projects and game developers to enhance the mini-game experience and expand its reach.",
      },
    ],
  },
  {
    phase: "Phase 3: NFT Release",
    title: "Minting Blockchain Doggs",
    description: [
      {
        paragraph:
          "Design and mint 10,000 unique Blockchain Doggs NFTs, with detailed guides and announcements for minting.",
      },
      {
        paragraph:
          "Marketing Campaign: Partner with influencers and launch targeted ad campaigns to increase awareness within the blockchain community.",
      },
    ],
  },
  {
    phase: "Phase 4: Expansion and Development",
    title: "New Content",
    description: [
      {
        paragraph:
          "Roll out updates with new games, levels, and adventures. Introduce limited edition NFTs and special events.",
      },
      {
        paragraph:
          "Community Engagement: Regularly host events, challenges, and competitions with rewards, and establish a community-driven decision-making process for future developments.",
      },
      {
        paragraph:
          "Listings",
      }
    ],
  },
  {
    phase: "Phase 5: Scaling and Partnerships",
    title: "Strategic Collaborations",
    description: [
      {
        paragraph:
          "Partner with additional blockchain projects and game developers, and explore cross-platform integrations to enhance the gaming experience.",
      },
      {
        paragraph:
          "Market Expansion: Expand marketing efforts to reach a global audience, introduce multilingual support, and host region-specific events.",
      },
    ],
  },
  {
    phase: "Phase 6: Long-Term Vision",
    title: "Sustainable Ecosystem",
    description: [
      {
        paragraph:
          "Ensure continuous development and innovation to keep the community engaged, regularly gather and implement user feedback to improve the platform.",
      },
      {
        paragraph:
          "Future Roadmap: Announce and detail long-term plans and goals for the project’s growth, explore opportunities in emerging technologies like AR/VR, and develop a AAA game for the Blocky World.",
      },
    ],
  },
];
export const vesting_schedule = [
  {
    title: "Team and Advisors:",
    description: [
      {
        subtitle: "6-month cliff period",
      },
      {
        subtitle: "Linear vesting over 24 months post-cliff",
      },
    ],
  },
  {
    title: "Ecosystem Fund and Play-to-Earn Rewards:",
    description: [
      {
        subtitle:
          "Gradual release over 36 months to ensure long-term sustainability and growth.",
      },
    ],
  },
  {
    title: "Marketing and Community:",
    description: [
      {
        subtitle: "Initial release of 20% at TGE (Token Generation Event)",
      },
      {
        subtitle: "Remaining tokens vested linearly over 12 months",
      },
    ],
  },
];
