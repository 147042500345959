import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import BtnConnect from "../buttons/BtnConnect";
import BtnMenu from "../buttons/BtnMenu";

const Layout = () => {
  const history = useNavigate();
  const location = useLocation();
  const [isOpenMenus, setIsOpenMenus] = useState(false);

  const page = location.pathname?.split('/')?.[1];

  const isMarket = ['market', 'shop', 'raffles', 'leaderbaord'].includes(page)

  const handleOpenMenus = () => {
    setIsOpenMenus(!isOpenMenus);
    const bodyElm = document.querySelector('body');
    if (!isOpenMenus) {
      bodyElm.classList.add('overflow-hidden');
    }
    else {
      bodyElm.classList.remove('overflow-hidden');
    }
  }

  const pushLink = (url) => {
    history(url);
    if (isOpenMenus) {
      handleOpenMenus();
    }
  }
  return (
    <>
      <div className={`${isMarket ? 'bg-[#f5f5f9]' : ''} `}>
        <div className="flex flex-col">
          <div className={(isMarket ? 'lg:top-8' : 'lg:top-14') + " absolute z-20 top-8  left-1/2 lg:left-14 transform -translate-x-1/2 lg:translate-x-0 lg-translate-x-0"}>
            <BtnMenu type={isMarket ? 'market' : null} onClick={handleOpenMenus} />
          </div>
        </div>

        <div className={`bg-menuPage fixed z-50 h-screen w-screen duration-300 ${isOpenMenus ? 'top-0' : 'top-full'} left-0`}
          style={{
            backgroundImage: `url('/svg/bgMenu.svg')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}>


          <div className="flex-none h-full relative">
            <div className="absolute z-10 top-8 lg:top-12 right-4 transform -translate-x-1/2">
              <button className="w-8 lg:w-14 rounded-full flex items-center justify-center" onClick={handleOpenMenus}>
                <img className="user-drag-none" src="/svg/close.svg" alt="close" />
              </button>
            </div>

            <div className="absolute  z-10 top-8 lg:top-10 right-20 lg:right-36 transform -translate-y-1">
              <BtnConnect />
            </div>

            <div className="imgDog absolute bottom-0 lg:bottom-0 left-0 lg:left-0 transform flex lg:block">
              <img className="ml-auto mr-auto w-3/5 lg:w-fit user-drag-none" src="/svg/dog.svg" alt="menu_text" />
            </div>

            <div className="listMenu pt-28 lg:pt-0 pl-8 lg:pl-0 lg:w-screen lg:absolute right-4 lg:right-14 transform">
              <div className="lg:w-3/5 lg:flex lg:justify-between lg:ml-auto">

                <ul className="">
                  <li className="ml-auto flex" onClick={() => pushLink('')}>
                    <p className="text-menu  text-hover-pink relative cursor-pointer">
                      Home
                    </p>

                  </li>

                  <li className="ml-auto flex cursor-pointer" onClick={() => pushLink('/about')}>
                    <p className="text-menu  text-hover-pink relative cursor-pointer">
                      About Us
                    </p>

                  </li>
                  <li className="ml-auto flex cursor-pointer" onClick={() => window.open("https://blockchain-doggs.gitbook.io/blockchain-doggs", '_blank')}>
                    <p className="text-menu  text-hover-pink  relative cursor-pointer">
                      Gitbook
                    </p>
                  </li>
                  
                </ul>
                <ul className="">
                  <li className="ml-auto flex lg:justify-end cursor-pointer"
                    // onClick={() => window.open("https://blockchain-doggs.gitbook.io/blockchain-doggs/utility", '_blank')}
                    onClick={() => pushLink('/buy-blocky')}
                  >
                    <p className="text-menu  text-hover-pink relative cursor-pointer">
                      Buy $Blocky
                    </p>
                  </li>
                  <li className="ml-auto flex cursor-pointer lg:justify-end " onClick={() => pushLink('/mini-game')}>
                    <p className="text-menu  text-hover-pink  relative cursor-pointer">
                    Mini Game
                    </p>
                  </li>
                  <li className="ml-auto flex lg:justify-end cursor-pointer" onClick={() => window.open("https://blockchain-doggs.gitbook.io/blockchain-doggs/roadmap", '_blank')}>
                    <p className="text-menu  text-hover-pink relative cursor-pointer">
                      Roadmap
                    </p>
                  </li>
                </ul>
              </div>

            </div>

            <div className="listSocial lg:absolute z-20 right-4 lg:right-14 transform mb-3 lg:mb-0">
              <div className="w-screen">
                <div className="listSocial_list lg:ml-auto flex lg:justify-between lg:w-2/5">
                  <ul className="flex mr-auto ml-auto lg:mr-0 relative">
                    <li className="flex lg-2 lg:ml-0">
                      <img className="w-14 lg:w-fit h-14 lg:h-fit block cursor-pointer user-drag-none" src="/svg/social/youtube.svg" alt="menu_youtube"
                        onClick={() => window.open("https://www.youtube.com/watch?v=LWMffp5d2Vc", '_blank')}
                      />
                    </li>
                    <li className="flex lg-2 lg:ml-4">
                      <img className="w-14 lg:w-fit h-14 lg:h-fit block cursor-pointer user-drag-none" src="/svg/social/x.svg" alt="menu_twitter"
                        onClick={() => window.open(" https://x.com/BlockchainDoggs", '_blank')} />
                    </li>
                    {/* <li className="flex lg-2 lg:ml-4">
                      <img className="w-14 lg:w-fit h-14 lg:h-fit block cursor-pointer user-drag-none" src="/svg/social/instagram.svg" alt="menu_instagram" />
                    </li>
                    <li className="flex lg-2 lg:ml-4">
                      <img className="w-14 lg:w-fit h-14 lg:h-fit block cursor-pointer user-drag-none" src="/svg/social/4.svg" alt="menu_youtube" />
                    </li> */}
                    <li className="flex lg-2 lg:ml-4">
                      <img className="w-14 lg:w-fit h-14 lg:h-fit block cursor-pointer user-drag-none" src="/svg/social/telegram.svg" alt="menu_twitter"
                        onClick={() => window.open("https://t.me/blockchaindoggs", '_blank')} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="textBlockchainWrapper lg:absolute z-10 bottom-4 right-4 lg:right-14 transform mb-3 lg:mb-0">
              <div className="w-screen flex">
                <div className="mr-auto lg:mr-0 ml-auto lg:pl-4 flex justify-between lg:w-3/5">
                  <button className=" text-black font-bold rounded-full flex items-center justify-center relative"         >
                    <p className="text-blockchain  relative cursor-pointer z-10">
                      Blockchain Doggs
                    </p>
                    <p className="text-blockchain-before absolute top-0 left-0 z-20 cursor-pointer">
                      Blockchain Doggs
                    </p>
                  </button>
                </div>
              </div>
            </div>


            <div className="listFooterMenu lg:absolute z-10 bottom-4 lg:bottom-4 lg:pb-2 right-4 lg:right-14 transform">
              <div className="w-screen">
                <div className="mr-auto lg:mr-0 ml-auto flex lg:justify-between lg:w-2/5">
                  <ul className="flex  mr-auto lg:mr-0 ml-auto">
                    <li className="flex justify-between ml-0">
                      <p className="text-menu-bottom uppercase relative cursor-pointer">
                        Privacy policy
                      </p>
                    </li>
                    <li className="flex justify-between ml-4">
                      <p className="text-menu-bottom uppercase relative cursor-pointer">
                        Terms of use
                      </p>
                    </li>
                    <li className="flex justify-between  ml-4">
                      <p className="text-menu-bottom uppercase relative cursor-pointer">
                        Ip rights
                      </p>
                    </li>
                    <li className="flex justify-between  ml-4">
                      <p className="text-menu-bottom uppercase relative cursor-pointer">
                        Careers
                      </p>
                    </li>
                  </ul>
                </div>

              </div>

            </div>

          </div>
        </div>
        <Outlet />
      </div>
    </>
  )
};

export default Layout;