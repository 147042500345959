/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, Fragment } from "react";
import { AnimatePresence, m, motion } from "framer-motion";

const ImageSlider = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [nextImage, setNextImage] = useState(1);
  const images = [4, 6] || Array.from(Array(10).keys());

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  //     setNextImage((prevNext) => (prevNext + 1) % images.length);
  //   }, 2000);

  //   return () => clearInterval(interval);
  // }, [images.length]);

  useEffect(() => {
    let number = 0;
    const interval = setInterval(() => {

      const imageElements = document.querySelectorAll(".slider-image");

      imageElements.forEach((elm, index) => {
        if (number % 10 == index) {
          elm?.classList.add("falling-animation");
          elm.style.zIndex = number + 1;
        }
        else {
          elm?.classList.remove("falling-animation")
        }
      })
      number++;
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);


  useEffect(() => {
    const imageElement = document.querySelectorAll(".slider-image");
    console.log({ imageElement })
    if (imageElement) {
      imageElement?.[1]?.classList?.add("falling-animation");
    }
  }, [currentImage, images]);

  return (
    <div
      className="slider-container"
      style={{ position: "relative", overflow: "hidden" }}
    >
      <AnimatePresence initial={false}>
        <img
          key={currentImage}
          // src={`/nfts-slide/${images[currentImage]}.webp`}
          src={`/nfts-slide/${images[currentImage]}.webp`}
          alt={`Image ${currentImage}`}
          className="slider-image-default"
          style={{ top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}
        //  style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
        />

        {/* {images.map(num => (
          <motion.img
            key={num}
            // src={`/nfts-slide/${images[currentImage]}.webp`}
            src={`images/slide/${num + 1}.png`}
            alt={`Image ${num}`}
            className="slider-image"
            style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1, }}
          />
        ))} */}



        {/* <img
          key={currentImage}
          src={`/nfts-slide/${images[currentImage]}.webp`}
          alt={`Image ${currentImage}`}
          className="slider-image"
          style={{ top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}
            //  style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
        /> */}
        <motion.img
          key={currentImage}
          // src={`/nfts-slide/${images[currentImage]}.webp`}
          src={`/images/slideVer3/_1.webp`}
          alt={`Image ${currentImage}`}
          className="slider-image"
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1, }}
        />
        <motion.img
          key={nextImage}
          // src={`/nfts-slide/${images[nextImage]}.webp`}
          src={`/images/slideVer3/_2.webp`}
          alt={`Image ${nextImage}`}
          className="slider-image"
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
        // initial={{ scale: 1, opacity: 0, y: "-100%" }}
        // animate={{ scale: 1, opacity: 1, y: 0 }} // Add transformOrigin to ensure the bounce is from the top
        // exit={{ opacity: 0,  y: 0 }} // No exit animation for the next image
        // transition={{
        //   type: "spring",
        //   delay: 0.3,
        //   bounce: 0.8,
        //   damping: 10,
        // }} // Add bottom: 0 to constrain the image within the container

        />
        <motion.img
          key={currentImage}
          // src={`/nfts-slide/${images[currentImage]}.webp`}
          src={`/images/slideVer3/_3.webp`}
          alt={`Image ${currentImage}`}
          className="slider-image"
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1, }}
        />

        <motion.img
          key={currentImage}
          src={`/images/slideVer3/_4.webp`}
          alt={`Image ${currentImage}`}
          className="slider-image"
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1, }}
        />

        <motion.img
          key={currentImage}
          src={`/images/slideVer3/_5.webp`}
          alt={`Image ${currentImage}`}
          className="slider-image"
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1, }}
        />
        <motion.img
          key={currentImage}
          src={`/images/slideVer3/_6.webp`}
          alt={`Image ${currentImage}`}
          className="slider-image"
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1, }}
        />
        <motion.img
          key={currentImage}
          src={`/images/slideVer3/_7.webp`}
          alt={`Image ${currentImage}`}
          className="slider-image"
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1, }}
        />
        <motion.img
          key={currentImage}
          src={`/images/slideVer3/_8.webp`}
          alt={`Image ${currentImage}`}
          className="slider-image"
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1, }}
        />
        <motion.img
          key={currentImage}
          src={`/images/slideVer3/_9.webp`}
          alt={`Image ${currentImage}`}
          className="slider-image"
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1, }}
        />
        <motion.img
          key={currentImage}
          src={`/images/slideVer3/_10.webp`}
          alt={`Image ${currentImage}`}
          className="slider-image"
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1, }}
        />
      </AnimatePresence>
    </div>
  );
};

function BouncyElement() {
  return (
    <motion.div
      initial={{ scale: 1 }}
      animate={{ scale: 1.2 }}
      transition={{ type: "spring", stiffness: 300, damping: 20 }}
    >
      <div style={{ width: "100px", height: "100px", background: "blue" }} />
    </motion.div>
  );
}
const NFTSlider = () => {
  return (
    // <div className="hidden max-w-5xl lg:flex items-center justify-center nft-show w-2/4 lg:w-9/12 mb-8">
    <div className="hidden lg:flex items-center justify-center nft-show w-2/4 mb-8">
      <div className="flex-1 w-4/6	mr-2">
        <ImageSlider />
      </div>
      <div className="flex-2 flex flex-col w-2/6	 ">
        <div className="flex-1">
          <img
            src="/nfts-slide/2.webp"
            alt="Image 2"
            className="w-full h-full object-cover p-1"
          />
        </div>
        <div className="flex-1 relative">
          <img
            src="/nfts-slide/3.webp"
            alt="Image 3"
            className="w-full h-full object-cover p-1"
          />
          <img
            src="/svg/home/mintingsoon.svg"
            alt="Image 3"
            className="absolute top-0 right-0 translate-x-full w-8/12"
            style={{ top: '-46%', transform: 'translateX(calc(100% - 18px))' }}
          />
        </div>
      </div>
    </div>
  );
};

const NFTStatic = () => {
  return (
    <div className="flex lg:hidden items-center justify-center lg:mt-8 nft-show">
      <div className="">
        <img
          src="/nfts-slide/4.webp"
          alt="Image 2"
          className="w-full h-full object-cover p-1"
        />
      </div>
      <div className="">
        <img
          src="/nfts-slide/2.webp"
          alt="Image 2"
          className="w-full h-full object-cover p-1"
        />
      </div>
      <div className="">
        <img
          src="/nfts-slide/3.webp"
          alt="Image 3"
          className="w-full h-full object-cover p-1"
        />
      </div>
    </div>
  );
};
const NFTPresentation = () => {
  return (
    <Fragment>
      <NFTSlider />
      <NFTStatic />
    </Fragment>
  );
};

export default NFTPresentation;
