

const BtnConnect = ({ className = "", type = '', ...dist }) => {
    return <>
        <button {...dist} className={className + (type == 'raffles' ? 'lg:w-[258px]' : 'lg:w-72') + " btn-menu w-44  h-11 lg:h-20 border border-solid bg-menu hover:bg-none hover:bg-hover-pink text-black font-bold py-4 px-4 rounded-full flex items-center justify-center"} >
            <img className="w-2/12 lg:w-max mr-2 lg:mr-4 user-drag-none" src="/svg/connect.svg" alt="menu" />
            {/* {type == 'raffles' ? <p className="font-dongo-regular text-[17.74px] text-white">Connect Wallet</p> : <img className="w-8/12 lg:w-max user-drag-none" src="/svg/connectText.svg" alt="menu_text" />} */}
            <img className="w-8/12 lg:w-max user-drag-none" src="/svg/connectText.svg" alt="menu_text" />
        </button>
    </>
}

export default BtnConnect;