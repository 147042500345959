import { useNavigate } from "react-router-dom";
import dogImg from "../../assets/images/dogFooter.png";
function Footer({ backgroundColor }) {
  const history = useNavigate();
  const pushLink = (url) => {
    history(url);
  };
  
  return (
    <>
      <div className="flex relative w-full z-20">
        <p className="text-before-footer absolute z-30">
          Start your journey with Blockchain Doggs
        </p>
        {/* <img src="/before-footer.svg" className="w-full relative z-20" /> */}
        <img
          className="w-full relative z-20 user-drag-none"
          src="/svg/home/footer.svg"
        />
        {/* <img className="w-full relative z-20 user-drag-none" src="/images/homepage/footer.webp" /> */}

        {/* <img style={{ width: '41%' }} className="absolute w-1/2 left-1/2 -translate-x-1/2 bottom-0 z-20 user-drag-none" src="/svg/home/dog.svg" /> */}
        {/* <img style={{ width: '41%' }} className="absolute w-1/2 left-1/2 -translate-x-1/2 bottom-0 z-20 user-drag-none" src="/images/home/dogFooter.png" /> */}
        <img
          style={{ width: "41%" }}
          className="absolute w-1/2 left-1/2 -translate-x-1/2 bottom-0 z-20 user-drag-none"
          src={dogImg}
        />
        <div
          className="absolute top-0 w-full h-1/2 z-10"
          style={{
            background: backgroundColor
              ? backgroundColor
              : "rgb(236, 231, 247)",
          }}
        ></div>
        <div
          className="absolute bottom-0 w-full h-1/2 z-10"
          style={{ background: "#2E1F40" }}
        ></div>
      </div>
      <div className="footer pt-10 pl-4 pr-4 lg:pt-28 pb-8 lg:pb-12 flex flex-col justify-center items-center relative w-full">
        <div className="w-full lg:w-9/12 flex lg:flex flex-col lg:flex-row flex-wrap ml-auto mr-auto">
          <div className="w-full lg:w-1/2 flex flex-nowrap lg:flex-wrap">
            <div className="w-1/2 lg:w-1/2">
              <ul className="">
                <li className="ml-auto flex" onClick={() => pushLink("")}>
                  <p className="text-menu-footer  text-hover-pink relative cursor-pointer">
                    Home
                  </p>
                </li>
                <li
                  className="ml-auto flex cursor-pointer"
                  // onClick={() => window.open("https://blockchain-doggs.gitbook.io/blockchain-doggs/utility", '_blank')}
                  onClick={() => pushLink("/buy-blocky")}
                >
                  <p className="text-menu-footer  text-hover-pink relative cursor-pointer">
                    Buy $Blocky
                  </p>
                </li>

                <li
                  className="ml-auto flex  cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://blockchain-doggs.gitbook.io/blockchain-doggs/roadmap",
                      "_blank"
                    )
                  }
                >
                  <p className="text-menu-footer  text-hover-pink relative cursor-pointer">
                    Roadmap
                  </p>
                </li>
              </ul>
            </div>
            <div className="w-1/2 lg:w-1/2 mb-8">
              <ul className="">
                <li
                  className="ml-auto flex cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://blockchain-doggs.gitbook.io/blockchain-doggs",
                      "_blank"
                    )
                  }
                >
                  <p className="text-menu-footer  text-hover-pink  relative cursor-pointer">
                    Gitbook
                  </p>
                </li>
                <li
                  className="ml-auto flex  cursor-pointer"
                  onClick={() => pushLink("/mini-game")}
                >
                  <p className="text-menu-footer  text-hover-pink relative cursor-pointer">
                    Mini Game
                  </p>
                </li>
                <li
                  className="ml-auto flex cursor-pointer"
                  onClick={() => pushLink("/about")}
                >
                  <p className="text-menu-footer  text-hover-pink relative cursor-pointer">
                    About Us
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="lg:w-1/2 ">
            <div className="listSocialFooter flex flex-wrap">
              <div className="lg:ml-auto lg:mb-6 w-full flex lg:justify-between">
                <ul className="flex mr-auto lg:ml-auto lg:mr-0 relative">
                  <li className="flex lg-2 lg:ml-0">
                    <img
                      className="lg:w-16  h-14 lg:h-fit block cursor-pointer user-drag-none"
                      src="./svg/social/youtube.svg"
                      alt="menu_youtube"
                      onClick={() =>
                        window.open(
                          "https://www.youtube.com/watch?v=LWMffp5d2Vc",
                          "_blank"
                        )
                      }
                    />
                  </li>
                  <li className="flex lg-2 ml-2 lg:ml-4">
                    <img
                      className="lg:w-16 h-14 lg:h-fit block cursor-pointer user-drag-none"
                      src="./svg/social/x_footer.svg"
                      alt="menu_twitter"
                      onClick={() =>
                        window.open("https://x.com/BlockchainDoggs", "_blank")
                      }
                    />
                  </li>
                  {/* <li className="flex lg-2 ml-2 lg:ml-4">
                    <img
                      className="lg:w-16  h-14 lg:h-fit block cursor-pointer user-drag-none"
                      src="./svg/social/instagram.svg"
                      alt="menu_instagram"
                    />
                  </li>
                  <li className="flex lg-2 ml-2 lg:ml-4">
                    <img
                      className="lg:w-16  h-14 lg:h-fit block cursor-pointer user-drag-none"
                      src="./svg/social/4.svg"
                      alt="menu_youtube"
                    />
                  </li> */}
                  <li className="flex lg-2 ml-2 lg:ml-4">
                    <img
                      className="lg:w-16 h-14 lg:h-fit block cursor-pointer user-drag-none"
                      src="./svg/social/telegram.svg"
                      alt="menu_twitter"
                      onClick={() =>
                        window.open("https://t.me/blockchaindoggs", "_blank")
                      }
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="lg:w-full flex">
            <div className="text-sm lg:text-x" style={{ color: "#968f9f" }}>
              © 2024 All rights reserved by Blockchain doggs.
            </div>
            <div className="relative ml-auto hidden lg:block">
              <p className="text-blockchain-footer  relative cursor-pointer z-10">
                Blockchain Doggs
              </p>
              <p className="text-blockchain-footer-before absolute top-0 left-0 z-20 cursor-pointer">
                Blockchain Doggs
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
