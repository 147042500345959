import { useEffect, useRef } from "react";
import bg_story_bot_img from "../../assets/images/bg_story_bot.png";
import bg_story_bot_v2_img from "../../assets/images/bg_story_bot_v2.png";
import { useLocation } from "react-router-dom";

const AboutUs = () => {
  const location = useLocation();
  const teamRef = useRef(null);

  useEffect(() => {
    if (location.hash === "#team" && teamRef.current) {
      // teamRef.current.scrollIntoView({ behavior: 'smooth' });
      // Optionally update the URL
      window.history.replaceState(null, null, "#team");
      setTimeout(() => {
        teamRef.current.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <>
      <div className="">
        <div className="flex flex-col">
          <div className="flex-none lg:h-screen relative">
            <div className="pt-0 relative">
              <div className="flex flex-col items-center justify-center">
                <div className="lg-bg-top-about w-full relative flex flex-col items-center">
                  <img
                    className="w-full user-drag-none"
                    src="/svg/aboutUs/bg.svg"
                    alt="bg"
                  />
                  <img
                    style={{ bottom: "-1vh" }}
                    className="hidden w-full lg:block absolute bottom-0 user-drag-none"
                    src="/svg/aboutUs/bg_2.svg"
                    alt="bg_2"
                  />
                </div>
              </div>
            </div>

            <div className="pt-0 relative">
              <div className="flex flex-col flex-wrap items-center justify-center">
                <div className="relative flex flex-col items-center">
                  {/* <img className='w-32 lg:w-fit h-8 lg:h-fit mb-5 lg:mb-0 mt-8 lg:mt-0 left-1/2 lg:bottom-0 lg:-translate-y-1/2 user-drag-none'
                  src="/svg/aboutUs/aboutUs.svg"
                  alt="aboutUs" /> */}
                  <div className="relative">
                    <p className="text-aboutUs relative lg:-translate-y-1/3">
                      {" "}
                      About Us{" "}
                    </p>
                    <p className="text-aboutUs-before absolute top-0 left-0 z-20 lg:-translate-y-1/3">
                      {" "}
                      About Us{" "}
                    </p>
                  </div>

                  {/* <img className='max-w-64 lg:max-w-fit mb-8 user-drag-none' src="/svg/aboutUs/learnMore.svg" alt="learnMore" /> */}

                  <div className="relative">
                    <p className="text-learnMore relative lg:-translate-y-full">
                      Learn more about Blockchain Doggs
                    </p>
                    <p className="text-learnMore-before absolute top-0 left-0 lg:-translate-y-full">
                      Learn more about Blockchain Doggs
                    </p>
                  </div>
                </div>
                <div className="flex w-full items-center justify-center mb-16">
                  <div className="flex flex-wrap lg:flex-nowrap lg:flex-row pl-4 pr-4 lg:pl-0 lg:pr-0 lg:w-9/12 lg-max-w-1122">
                    <div className="w-full lg:w-80 relative flex justify-center ">
                      <div
                        className="dogAboutUs w-32 lg:w-11/12 lg:max-w-72 flex-none h-40 lg:h-96 relative z-10 mb-8 lg:mb-0"
                        style={{
                          backgroundImage: `url('/images/aboutUs/dog.webp')`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          // borderRadius: '68px', 38px
                        }}
                      ></div>

                      <div
                        className="dogAboutUs absolute top-0 w-36 lg:w-full lg:max-w-72 flex-none h-44 lg:h-96 "
                        style={{
                          backgroundImage: `url('/images/aboutUs/dog.webp')`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          borderRadius: "68px",
                          opacity: "0.7",
                          filter: "blur(42px)",
                        }}
                      ></div>
                    </div>
                    <div className="lg-width-100-minus-w80 lg:pl-6 lg:pt-8">
                      <p className="text-left textSection mb-4">
                        Blockchain Doggs is a premier GameFi project, launching
                        with our initial mini-game and a standout PFP NFT
                        collection. Our collection features 10,000 unique Dogg
                        NFTs on the Solana blockchain, offering much more than
                        just digital art. Our tap-to-earn mini-game will be
                        deployed on the Telegram social media app, adding
                        another layer of engagement and fun for our community.
                      </p>
                      <p className="text-left textSection pt-2">
                        As a Blockchain Doggs NFT holder, you gain access to
                        exclusive, members-only perks such as future airdrops,
                        limited-edition merchandise, collectible toys, and a
                        comprehensive rewards system. But that’s not all—we’re
                        building a robust ecosystem where NFT holders can
                        potentially earn passive income through the Investdex.io
                        data analytics platform!
                      </p>
                      <p className="text-left textSection">
                        Join us in revolutionizing GameFi
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full items-center justify-center mb-16 lg:mb-32 relative">
                  <div
                    className="absolute top-0 left-0 w-full h-full"
                    style={{
                      backgroundImage: `url('/svg/aboutUs/bg_story.svg')`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      filter: "blur(27px)",
                    }}
                  ></div>
                  <div className="w-full flex items-center justify-center overflow-hidden">
                    <div className="flex-1 lg:w-9/12 lg-max-w-1122 flex flex-col relative lg:pl-0">
                      <div className="w-full mb-6 px-4">
                        {/* <img className='user-drag-none' src="/svg/aboutUs/story.svg" alt="learnMore" /> */}
                        <div className="relative">
                          <p className="text-story">Story</p>
                          <p className="text-story-before absolute top-0 left-0 z-20">
                            Story
                          </p>
                        </div>
                      </div>

                      <div className="z-10 px-4">
                        <p className="text-left textSection mb-4">
                          The story unfolds with the founder's decision to
                          harness his expertise in trading and blockchain
                          technology to craft an NFT collection featuring his
                          family's beloved Cockapoo. With meticulous care and
                          innovation, we sculpt the most endearing and stylish
                          dog that starts life on the blockchain.
                        </p>
                        <p className="text-left textSection ">
                          The timing couldn't be more opportune, as the rising
                          wave of dog-themed projects in the crypto meme
                          industry creates the perfect opportunity for our
                          beloved Cockapoo to lead in this burgeoning space.
                          We’ve infused this collection with an exciting,
                          interactive experience through a tap-to-earn mini-game
                          on Telegram. NFT holders can engage in the game, earn
                          rewards, and become part of a growing GameFi
                          community.
                        </p>
                      </div>
                      <div className="flex sm:flex-none justify-center sm:justify-end">
                        <img
                          className="hidden sm:block user-drag-none sm:mt-[-35px] lg:mt-[-60px]"
                          src={bg_story_bot_img}
                          alt="bg_story_bot_img"
                        />

                        <img
                          className="sm:hidden user-drag-none mt-[-30px] min-w-[100vw]"
                          src={bg_story_bot_v2_img}
                          alt="bg_story_bot_img"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full items-center justify-center relative pb-12 mb-12">
                  <div
                    id="team"
                    ref={teamRef}
                    className="absolute -top-16 lg:-top-24"
                  ></div>
                  <div
                    className="absolute top-0 left-0 w-full h-full"
                    style={{
                      backgroundImage: `url('/svg/aboutUs/bg_team.svg')`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      filter: "blur(27px)",
                    }}
                  ></div>
                  <div className="w-full flex items-center justify-center overflow-hidden">
                    <div className="lg:w-9/12 lg-max-w-1122 flex flex-col relative pl-4 pr-4 lg:pl-0">
                      <div className="w-full mb-4 lg:mb-8 flex lg:justify-center transform">
                        {/* <img className='user-drag-none' src="/svg/aboutUs/team.svg" alt="team" /> */}
                        <div id="team2" className="relative">
                          <p className="text-team">Team</p>
                          <p className="text-team-before absolute top-0 left-0 z-20">
                            Team
                          </p>
                        </div>
                      </div>

                      <p className="text-start lg:text-center textSection mb-10">
                        We are a committed team of seasoned professionals from
                        the technology, art, and finance industries, dedicated
                        to blending creativity with cutting-edge technology. We
                        boast robust development teams that have created a data
                        analytics tool akin to Dextools, complemented by a
                        talented group of 2D artist, illustrators and pixel
                        artists who continually push artistic limits...
                      </p>

                      <div className="listTeamImg relative mb-8 lg:mb-0 flex justify-center">
                        <img
                          className="hidden lg:block user-drag-none mb-8 max-w-screen-2xl h-44 lg:h-fit absolute lg:relative top-0 left-0"
                          src="/svg/aboutUs/listTeam.svg"
                          alt="listTeam"
                        />
                        <img
                          className="lg:hidden user-drag-none mb-8  h-44 lg:h-fit absolute lg:relative top-0 left-0"
                          src="/svg/aboutUs/listTeamMobile.svg"
                          alt="listTeam"
                        />
                      </div>

                      <p className="text-start lg:text-center textSection">
                        Our main office is located in the UK, but our team
                        extends globally, with remote members
                        <br className="hidden lg:inline-block" /> based in
                        Dubai, the Philippines, and Singapore. Our team, with
                        its deep expertise in design
                        <br className="hidden lg:inline-block" /> and economics,
                        is committed to creating NFTs that are not only visually
                        stunning and unique
                        <br className="hidden lg:inline-block" /> but also
                        poised to rise as a leading global brand.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
