import { Fragment, useState } from "react";
import Navbar from "./../../components/navbar/navbar";
import NFTSlider from "./../../components/nft-slider/slider";

function OldHome() {
  const [isOpenSound, setIsOpenSound] = useState(false);
  const [isOpenMenus, setIsOpenMenus] = useState(false);

  const handleOpenSound = () => {
    setIsOpenSound(!isOpenSound);
    const artGalleryVideoElm = document.querySelector("#artGallery");
    console.log({ artGalleryVideoElm });
    if (isOpenSound) {
      console.log("muted=true");
      artGalleryVideoElm.muted = true;
    } else {
      console.log("muted=false");
      artGalleryVideoElm.muted = false;
    }
  };

  const handleOpenMenus = () => {
    setIsOpenMenus(!isOpenMenus);
    const bodyElm = document.querySelector("body");

    if (!isOpenMenus) {
      bodyElm.classList.add("overflow-hidden");
    } else {
      bodyElm.classList.remove("overflow-hidden");
    }
  };
  return (
    <div className="">
      {/* <Navbar /> */}
      <div className="flex flex-col">
        <div
          className="flex-none lg:h-screen relative"
          style={{
            backgroundImage: `url('/nfts-bg.svg'), url('/splatter-bg.svg')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          {/* video container */}
          <div className="hidden lg:block relative h-screen">
            <video
              id="artGallery"
              className="absolute inset-0 object-cover w-full h-full"
              autoPlay
              muted
              loop
              playsInline
            >
              {/* test sound
              <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" /> */}
              <source src="/ArtGallery.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            <div className="absolute top-4 lg:top-14 left-4 lg:left-14 transform">
              <button
                className="bg-menu hover:bg-none hover:bg-hover-pink w-56 text-black font-bold py-3 px-4 rounded-full flex items-center justify-center"
                onClick={handleOpenMenus}
              >
                <img className="mr-4" src="./svg/menu_3.svg" alt="menu" />
                <img src="./svg/menu_text.svg" alt="menu_text" />
              </button>
            </div>

            <div className="absolute bottom-1/2 lg:bottom-1/2 left-4 lg:left-14 transform">
              <img
                className="cursor-pointer w-10 h-10"
                src={isOpenSound ? "./svg/sound.svg" : "./svg/soundMuted.svg"}
                alt="sound"
                onClick={handleOpenSound}
              />
            </div>
            <div className="absolute bottom-4 lg:bottom-14 left-1/2 transform -translate-x-1/2">
              <button
                className="bg-yellow-400 hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded-full flex items-center justify-center"
                onClick={() =>
                  window.open("https://forms.gle/zVYPtvfXHuXUkVso9", "_blank")
                }
              >
                <img src="./money-icon.svg" alt="Your Image" />
                <span className="flex items-center lg:text-xl">WHITELIST!</span>
              </button>
            </div>
          </div>

          <div
            className={`bg-menuPage fixed z-10 h-screen w-screen duration-300 ${
              isOpenMenus ? "top-0" : "top-full"
            } left-0`}
            style={{
              backgroundImage: `url('/svg/bgMenu.svg')`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div className="flex-none h-full relative">
              <div className="absolute z-10 top-8 lg:top-14 right-4 transform -translate-x-1/2">
                <button
                  className="w-8 lg:w-14 rounded-full flex items-center justify-center"
                  onClick={handleOpenMenus}
                >
                  <img
                    className="user-drag-none"
                    src="./svg/close.svg"
                    alt="close"
                  />
                </button>
              </div>

              <div className="absolute  z-10 top-8 lg:top-14 right-20 lg:right-36 transform -translate-y-1">
                <button className="w-44 lg:w-72 h-11 lg:h-20 border border-solid border-white-translucent bg-menu hover:bg-none hover:bg-hover-pink text-black font-bold py-4 px-4 rounded-full flex items-center justify-center">
                  <img
                    className="w-2/12 lg:w-max mr-2 lg:mr-4 user-drag-none"
                    src="./svg/connect.svg"
                    alt="menu"
                  />
                  <img
                    className="w-8/12 lg:w-max user-drag-none"
                    src="./svg/connectText.svg"
                    alt="menu_text"
                  />
                </button>
              </div>

              <div className="absolute w-full lg:w-fit bottom-0 lg:bottom-0 left-0 lg:left-0 transform flex lg:block">
                <img
                  className="ml-auto mr-auto w-3/5 lg:w-fit user-drag-none"
                  src="./svg/dog.svg"
                  alt="menu_text"
                />
              </div>

              <div className="pt-32 lg:pt-0 pl-8 lg:pl-0 lg:w-screen lg:absolute top-28 lg:top-48 left-9 lg:left-inherit right-4 lg:right-14 lg:left-inherit transform">
                <div className="lg:w-3/5 lg:flex lg:justify-between lg:ml-auto">
                  <ul className="">
                    <li className="ml-auto flex    mb-7 lg:mb-10">
                      <img
                        className="block cursor-pointer user-drag-none scale-75 lg:scale-100 origin-top-left"
                        src="./svg/menus/home.svg"
                        alt="menu_home"
                      />
                    </li>
                    <li className="ml-auto flex    mb-7 lg:mb-10">
                      <img
                        className="block cursor-pointer user-drag-none scale-75 lg:scale-100 origin-top-left"
                        src="./svg/menus/aboutUs.svg"
                        alt="menu_aboutUs"
                      />
                    </li>
                    <li className="ml-auto flex    mb-7 lg:mb-10">
                      <img
                        className="block cursor-pointer user-drag-none scale-75 lg:scale-100 origin-top-left"
                        src="./svg/menus/github.svg"
                        alt="menu_github"
                      />
                    </li>
                  </ul>
                  <ul className="">
                    <li className="ml-auto flex lg:justify-end mb-7 lg:mb-10">
                      <img
                        className="block cursor-pointer user-drag-none scale-75 lg:scale-100 origin-top-left"
                        src="./svg/menus/utility.svg"
                        alt="menu_utility"
                      />
                    </li>
                    <li className="ml-auto flex lg:justify-end mb-7 lg:mb-10">
                      <img
                        className="block cursor-pointer user-drag-none scale-75 lg:scale-100 origin-top-left"
                        src="./svg/menus/theTeam.svg"
                        alt="menu_theTeam"
                      />
                    </li>
                    <li className="ml-auto flex lg:justify-end mb-7 lg:mb-10">
                      <img
                        className="block cursor-pointer user-drag-none scale-75 lg:scale-100 origin-top-left"
                        src="./svg/menus/roadMap.svg"
                        alt="menu_roadMap"
                      />
                    </li>
                  </ul>
                </div>
              </div>

              <div className="lg:absolute z-10 top-96 lg:bottom-32 lg:top-inherit right-4 lg:right-14 transform mb-3 lg:mb-0">
                {/* lg:w-screen absolute top-28 lg:top-48 left-9 lg:left-inherit right-4 lg:right-14 lg:left-inherit transform */}

                <div className="w-screen">
                  <div className="lg:ml-auto flex lg:justify-between lg:w-2/5">
                    <ul className="flex mr-auto ml-auto lg:mr-0">
                      <li className="flex lg-2 lg:ml-0">
                        <img
                          className="w-14 lg:w-fit h-14 lg:h-fit block cursor-pointer user-drag-none"
                          src="./svg/social/youtube.svg"
                          alt="menu_youtube"
                        />
                      </li>
                      <li className="flex lg-2 lg:ml-4">
                        <img
                          className="w-14 lg:w-fit h-14 lg:h-fit block cursor-pointer user-drag-none"
                          src="./svg/social/twitter.svg"
                          alt="menu_twitter"
                        />
                      </li>
                      <li className="flex lg-2 lg:ml-4">
                        <img
                          className="w-14 lg:w-fit h-14 lg:h-fit block cursor-pointer user-drag-none"
                          src="./svg/social/instagram.svg"
                          alt="menu_instagram"
                        />
                      </li>
                      <li className="flex lg-2 lg:ml-4">
                        <img
                          className="w-14 lg:w-fit h-14 lg:h-fit block cursor-pointer user-drag-none"
                          src="./svg/social/4.svg"
                          alt="menu_youtube"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="lg:absolute z-10 bottom-4 lg:bottom-32 right-4 lg:right-14 transform  mb-4 lg:mb-0">
                <div className="w-screen flex">
                  <div className="mr-auto lg:mr-0 ml-auto flex justify-between lg:w-3/5">
                    <button className=" text-black font-bold rounded-full flex items-center justify-center">
                      <img
                        className="user-drag-none"
                        src="./svg/blockchainDogs.svg"
                        alt="menu_text"
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div className="lg:absolute z-10 bottom-4 lg:bottom-20 lg:pb-2 right-4 lg:right-14 transform">
                <div className="w-screen">
                  <div className="mr-auto lg:mr-0 ml-auto flex lg:justify-between lg:w-2/5">
                    <ul className="flex  mr-auto lg:mr-0 ml-auto">
                      <li className="flex justify-between ml-0">
                        <img
                          className="block cursor-pointer user-drag-none"
                          src="./svg/listText/privacy_policy.svg"
                          alt="menu_policy"
                        />
                      </li>
                      <li className="flex justify-between ml-4">
                        <img
                          className="block cursor-pointer user-drag-none"
                          src="./svg/listText/terms_of_use.svg"
                          alt="menu_terms_of_use"
                        />
                      </li>
                      <li className="flex justify-between  ml-4">
                        <img
                          className="block cursor-pointer user-drag-none"
                          src="./svg/listText/ip_rights.svg"
                          alt="menu_ip_rights"
                        />
                      </li>
                      <li className="flex justify-between  ml-4">
                        <img
                          className="block cursor-pointer user-drag-none"
                          src="./svg/listText/careers.svg"
                          alt="menu_careers"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* static background container */}
          <div className="pt-40 relative lg:hidden">
            <div className="flex flex-col items-center justify-center">
              {/* <h1 className="text-blue-800 sm:text-3xl lg:text-6xl font-bold text-center mb-4">
                Blockchain Doggs
              </h1> */}
              <img src="/intro-text.svg" alt="NFTs" className="w-72" />

              <img src="/nfts.svg" alt="NFTs" />
              <div className="lg:bottom-14 mb-8 lg:mb-0">
                <button
                  className="bg-yellow-400 hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded-full flex items-center justify-center"
                  onClick={() =>
                    window.open("https://forms.gle/zVYPtvfXHuXUkVso9", "_blank")
                  }
                >
                  <img src="./money-icon.svg" alt="Your Image" />
                  <span className="flex items-center lg:text-xl">
                    WHITELIST!
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex-none lg:h-screen relative"
          style={{
            backgroundImage: `url('/section2-bg.svg')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="flex flex-col items-center justify-center h-full">
            <img src="/other-text.svg" alt="NFTs" className="w-2/4" />
            <div className="mt-0 mb-5">
              <h1 className="text-center font-bold text-2xl">
                The adventure begins here...
              </h1>
              <p className="text-center text-xl pt-2">
                10,000 stunningly unique Blockchain Doggs on the Solana
                blockchain, offering <br /> exclusive benefits and perks that
                the community can unlock by following the roadmap.
              </p>
            </div>
            <img
              src="/nfts-rows.svg"
              alt="NFTs"
              className="w-full lg:w-3/4 mt-2"
            />
          </div>
        </div>

        <div
          className="flex-none lg:h-screen relative"
          style={{
            backgroundImage: `url('/section2-bg.svg')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="flex flex-col items-center h-full lg:justify-center">
            <NFTSlider />
          </div>
        </div>

        <div
          className="flex-none lg:h-screen relative"
          style={{
            backgroundColor: `#ECE7F7`,
            backgroundSize: "cover",
          }}
        >
          <div className="flex flex-col md:flex-row lg:w-2/3 justify-center m-auto p-5">
            <div className="flex-none lg:h-screen lg:w-1/3 relative justify-center">
              {/* Content for the left side */}
              <div className="flex flex-col items-center justify-center h-full">
                <div
                  style={{
                    background: "radial-gradient(circle, #B69CFF, #916EED)",
                    display: "inline-block",
                  }}
                  className="rounded-xl mb-5"
                >
                  <img
                    src="/nft-faq.svg"
                    alt="Description of the image"
                    className=" m-0"
                  />
                </div>
                <button
                  className="text-white rounded-full px-6 py-3 flex items-center justify-between font-bold"
                  style={{ backgroundColor: "#F1771B" }}
                >
                  BUY NOW
                  <img
                    src="money-icon-white.svg" // Replace 'icon-url.png' with the URL of your icon image
                    alt="Description of the icon"
                    className="w-6 h-6 text-white ml-1"
                  />
                </button>
              </div>
            </div>

            <div
              className="flex-none lg:h-screen lg:w-1/2 relative m-auto lg:m-0 p-5 space-y-5 md:space-y-0 md:space-x-5"
              style={{
                backgroundSize: "cover",
              }}
            >
              <div className="flex flex-col justify-center h-full max-w-lg">
                <h1 className="left-0 font-extrabold text-4xl">FAQ</h1>
                <FAQ defaultShow={true} />
                <FAQ defaultShow={false} />
                <FAQ defaultShow={false} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex  m-auto">
          <img src="/before-footer.svg" className="w-100" />
        </div>
      </div>
    </div>
  );
}

const FAQ = ({ defaultShow }) => {
  const [showParagraph, setShowParagraph] = useState(defaultShow);

  const toggleParagraph = () => {
    setShowParagraph(!showParagraph);
  };

  return (
    <Fragment>
      <div className="flex mt-4">
        <div className="flex items-start">
          <img
            src="plus-icon.svg" // Replace 'plus-icon.svg' with the URL of your image
            alt="Description of the image"
            className={`w-6 h-6 mr-3 cursor-pointer transform transition-transform duration-300 ${
              showParagraph ? "rotate-45" : ""
            }`}
            onClick={toggleParagraph}
          />
          <div>
            <h2
              className="font-semibold"
              style={{ color: "#6E36E3" }}
              onClick={toggleParagraph}
            >
              Why HOLD BLOCKCHAIN DOGGS COINS?
            </h2>
            <div
              className={`text-sm -ml-10 mt-2 transition-opacity duration-300 ${
                showParagraph ? "opacity-100" : "opacity-0 h-0"
              }`}
            >
              Tokens distributed as rewards must be whitelisted onchain. Fill
              this form if you want to whitelist a token. Tokens distributed as
              rewards must be whitelisted onchain. Fill this form if you want to
              whitelist a token.
            </div>
          </div>
        </div>
      </div>
      <div className="border-b w-full my-2 border-gray-800 opacity-10"></div>
    </Fragment>
  );
};
export default OldHome;

// import { Fragment, useState } from "react";
// import ReactDOM from "react-dom/client";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Layout from "./components/pages/Layout";
// import Home from "./components/pages/Home";
// // import Blogs from "./pages/Blogs";
// // import Contact from "./pages/Contact";
// // import NoPage from "./pages/NoPage";
// // import "./App.css";

// export default function App() {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<Layout />}>
//           <Route index element={<Home />} />
//           {/* <Route path="blogs" element={<Blogs />} />   */}
//         </Route>
//       </Routes>
//     </BrowserRouter>
//   );

// }
