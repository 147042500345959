import MiniGame1 from "../../assets/images/mini-game-1.png";
import MiniGame10 from "../../assets/images/mini-game-10.png";
import Icon1 from "../../assets/svg/icon1.svg";
import Icon2 from "../../assets/svg/icon2.svg";
import Icon4 from "../../assets/svg/icon4.svg";
import Icon5 from "../../assets/svg/icon5.svg";
import Icon6 from "../../assets/svg/icon6.svg";
import Icon7 from "../../assets/svg/icon7.svg";
import Icon8 from "../../assets/svg/icon8.svg";
import PlayIcon from "../../assets/svg/play.svg";
import TelegramIcon from "../../assets/svg/telegram.svg";
import TwitterIcon from "../../assets/svg/twitter.svg";

export function MiniGameSection1() {
  return (
    <div
      className={`p-4 md:px-6 pt-28 md:pb-10 flex flex-col items-center relative`}
    >
      <img
        src={Icon1}
        alt=""
        className="absolute scale-50 md:scale-100 opacity-60 top-[10%] left-[20%] hidden md:block"
      />
      <img
        src={Icon1}
        alt=""
        className="absolute scale-50 md:scale-100 opacity-60 top-[40%] -left-[15%] md:left-[15%]"
      />
      <img
        src={Icon6}
        alt=""
        className="absolute scale-50 md:scale-100 opacity-60 top-[70%] left-[10%] hidden md:block"
      />
      <img
        src={Icon1}
        alt=""
        className="absolute scale-50 md:scale-100 opacity-60 top-[25%] right-[10%] hidden md:block"
      />
      {/* <img src={Icon3} alt="" className="absolute scale-50 md:scale-100 opacity-60 top-[25%] right-[90%]" /> */}
      <img
        src={Icon4}
        alt=""
        className="absolute scale-50 md:scale-100 opacity-60 top-[55%] -right-[10%] md:right-[30%]"
      />
      <img
        src={Icon5}
        alt=""
        className="absolute scale-50 md:scale-100 opacity-60 top-[10%] md:top-[0%] right-[20%] md:right-[40%] hidden md:block"
      />
      <img
        src={Icon2}
        alt=""
        className="absolute scale-50 md:scale-100 opacity-60 top-[45%] -right-[15%] md:right-[20%]"
      />
      <img
        src={Icon7}
        alt=""
        className="absolute scale-50 md:scale-100 opacity-60 top-[15%] -right-[20%] md:right-[25%]"
      />
      <img
        src={Icon8}
        alt=""
        className="absolute scale-50 md:scale-100 opacity-60 top-[70%] right-[15%] hidden md:block"
      />

      <div className="relative h-[200px] w-[200px]">
        <img
          src={MiniGame1}
          alt=""
          className="absolute max-w-none left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 scale-75 md:scale-100"
        />
      </div>

      <div className="relative text-[20px] md:text-[35px] text-center -tracking-[0.03em] leading-none mb-4">
        <div className="font-dongo-regular bg-gradient-2">
          Become a blockchain <br /> billionaire in Blocky World!
        </div>
        <div className="font-dongo-outline text-[#000000BA] absolute top-0">
          Become a blockchain <br /> billionaire in Blocky World!
        </div>
      </div>

      <div className="relative text-[46px] md:text-[84px] -tracking-[0.03em]">
        <div className="font-dongo-regular bg-gradient-1">MINI GAME</div>
        <div className="font-dongo-outline text-[#221F50] absolute top-0">
          MINI GAME
        </div>
      </div>

      <div className="flex gap-4">
        <button
          className="flex items-center gap-2 md:py-1 px-4 md:px-6 bg-[#4D66FF] rounded-[50px] font-dongo-regular text-white text-sm md:text-2xl shadow-1"
          onClick={() =>
            window.open("https://t.me/Blockchaindoggs_bot", "_blank")
          }
        >
          <img className="size-3.5 md:size-fit" src={PlayIcon} alt="" />
          <span className="mt-2">PLAY NOW</span>
        </button>

        <button
          className="flex items-center gap-2 md:py-1 px-4 md:px-6 bg-[#FFFFFF66] rounded-[50px] border border-[#0000004D] text-xs md:text-xl shadow-2"
          onClick={() => window.open("https://t.me/blockchaindoggs", "_blank")}
        >
          <img className="size-3.5 md:size-fit" src={TwitterIcon} alt="" />
          <img className="size-3.5 md:size-fit" src={TelegramIcon} alt="" />
          <span>Join Us</span>
        </button>
      </div>

      <div className="relative text-[18px] md:text-[40px] mt-4">
        <div className="font-dongo-regular bg-gradient-3">
          Learn more about Blockchain Doggs
        </div>
        <div className="font-dongo-outline text-[#321F50] absolute top-0">
          Learn more about Blockchain Doggs
        </div>
      </div>

      <div
        className={`bg-[url(${MiniGame10})] bg-cover bg-center inset-0 absolute blur-xl -z-10`}
      />
    </div>
  );
}
