import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/pages/Layout";
import Home from "./components/pages/Home";
import AboutUs from "./components/pages/AboutUs";
import OldHome from "./components/pages/OldHome";
// import Staking from "./components/pages/Staking";
// import System from "./components/pages/System";
// import Market from "./components/pages/Market";
// import MarketDetail from "./components/pages/MarketDetail";
// import Shop from "./components/pages/Shop";
// import Raffles from "./components/pages/Raffles";
// import RafflesDetail from "./components/pages/RafflesDetail";
// import LeaderBoard from "./components/pages/LeaderBoard";
import BuyBlocky from "./components/pages/BuyBlocky";
import MiniGame from "./components/pages/MiniGame";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="buy-blocky" element={<BuyBlocky />} />
          {/* <Route path="staking" element={<Staking />} /> */}
          {/* <Route path="system" element={<System />} /> */}
          {/* <Route path="market" element={<Market />} /> */}
          {/* <Route path="market/:id" element={<MarketDetail />} /> */}
          {/* <Route path="shop" element={<Shop />} /> */}
          {/* <Route path="raffles" element={<Raffles />} /> */}
          {/* <Route path="raffles/:id" element={<RafflesDetail />} /> */}
          {/* <Route path="leader-board" element={<LeaderBoard />} /> */}
          {/* testing   */}
          <Route path="oldHome" element={<OldHome />} />  
          <Route path="home-no-video" element={<Home isNoVideo={true} />} />  

          <Route path="mini-game" element={<MiniGame />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );

}