import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function MultipleItems({
  list,
  sliderRef,
  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  },
}) {
  return (
    <div className="slider-container-raffles">
      <Slider {...settings} ref={sliderRef}>
        {list.map((item, i) => (
          <div key={i}>{item}</div>
        ))}
      </Slider>
    </div>
  );
}

export default MultipleItems;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      // style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    >
      <div>
        <svg
          width="117"
          height="117"
          viewBox="0 0 117 117"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M46.0198 99.6938L77.7561 67.9088C82.9236 62.6925 82.9236 54.2588 77.7073 49.0425L45.9223 17.2088C44.4598 15.7463 42.1686 15.7463 40.7061 17.16C39.2436 18.5738 39.2436 20.865 40.6573 22.3275L72.3936 54.1125C74.6848 56.4038 74.6848 60.255 72.3936 62.5463L40.6086 94.3313C39.1461 95.745 39.1461 98.0363 40.6086 99.4988C42.0223 100.913 44.3136 100.913 45.7761 99.45L46.0198 99.6938Z"
            fill="#292D32"
          />
        </svg>
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + " w-fit h-fit"}
      // style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    >
      <div className="">
        <svg
          width="117"
          height="117"
          viewBox="0 0 117 117"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M70.9802 99.6938L39.2439 67.9088C34.0764 62.6925 34.0764 54.2588 39.2927 49.0425L71.0777 17.2088C72.5402 15.7463 74.8314 15.7463 76.2939 17.16C77.7564 18.5738 77.7564 20.865 76.3427 22.3275L44.6064 54.1125C42.3152 56.4038 42.3152 60.255 44.6064 62.5463L76.3914 94.3313C77.8539 95.745 77.8539 98.0363 76.3914 99.4988C74.9777 100.913 72.6864 100.913 71.2239 99.45L70.9802 99.6938Z"
            fill="#292D32"
          />
        </svg>
      </div>
    </div>
  );
}
