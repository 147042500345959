import { useEffect, useRef, useState } from "react";
import { roadmap, token_Allocation, vesting_schedule } from "../../data/data";
import BlockyComingSoon from "../BuyBlockyCard/BlockyComingSoon";
// import BuyBlockyCard from "../BuyBlockyCard/BuyBlockyCard";
import MultipleItems from "../slider/MultipleItems";
import Footer from "./Footer";

const Buy_Blocky = () => {
  const [slidesToShow, setSlidesToShow] = useState(4);
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const calculateOddSlidesToShow = () => {
    const width = window.innerWidth;
    let slides;
    if (width >= 1024) {
      slides = width / 360 - 0.5;
    } else {
      slides = width / 161 - 0.5;
    }
    return slides;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setSlidesToShow(calculateOddSlidesToShow());

    const handleResize = () => {
      setSlidesToShow(calculateOddSlidesToShow());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const setting_slide = {
    dots: false,
    slidesToShow: slidesToShow,
    infinite: false,
    centerMode: false,
    arrow: true,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(Math.ceil(newIndex));
    },
    afterChange: (index) => {
      setCurrentSlide(Math.ceil(index));
    },
  };

  const getMaxSlides = () => {
    const slick = sliderRef.current;
    if (slick) {
      const { slideCount } = slick.innerSlider.state;
      return Math.ceil(slideCount - slidesToShow);
    }
    return 0;
  };
  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  // Hàm xử lý khi nhấn nút Next
  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  return (
    <>
      <div className="relative">
        <div className="flex flex-col">
          <div className="flex-none relative mb-[80px]">
            <div className="pt-0 relative">
              <div className="flex flex-col items-center justify-center">
                <div className="lg-bg-top-about w-full relative flex flex-col items-center">
                  <img
                    className="w-full user-drag-none"
                    src="/svg/aboutUs/bg.svg"
                    alt="bg"
                  />
                  <img
                    style={{ bottom: "-1vh" }}
                    className="hidden w-full lg:block absolute bottom-0 user-drag-none"
                    src="/svg/aboutUs/bg_2.svg"
                    alt="bg_2"
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                backgroundImage: `url('/block-bg.svg')`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              className="flex flex-col items-center"
            >
              <div className="xl:absolute left-[5%] top-[90%] flex flex-col items-center xl:items-start max-w-[610px] z-20 p-[25px]">
                <div className="relative mb-[15px]">
                  <p className="font-dongo-regular text-dongo text-[32px] lg:text-[64px] xl:text-[84px] -translate-y-1/3 mb-[-50px]">
                    Buy $Blocky
                  </p>
                  <p className="font-dongo-regular text-dongo-before text-[32px] lg:text-[64px] xl:text-[84px] absolute top-0 left-0 z-20 -translate-y-1/3">
                    Buy $Blocky
                  </p>
                </div>
                <div className="">
                  <span className="font-IBM-sans font-bold text-sm xl:text-[36px] lg:text-xl xl:text-left text-center">
                    The $Blocky Launch will commence soon!
                  </span>
                </div>
                <button className="button-shadow bg-yellow-400 hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded-full flex items-center justify-center mb-12 w-[293px] mt-[13px]">
                  <img src="./svg/message.svg" alt="Your Image" />
                  <span className="flex items-center text-xs xl:text-2xl text-Dongo-medium">
                    Need help buying?
                  </span>
                </button>
              </div>

              {/* connect wallet */}
              <BlockyComingSoon />
            </div>
          </div>
        </div>
        <div className="hidden xl:block h-[350px] mt flex flex-col items-center justify-center">
          <div className="lg-bg-top-about w-full relative flex flex-col items-center">
            <img
              className="w-full user-drag-none"
              src="/block-bg.svg"
              alt="bg"
            />
          </div>
        </div>

        <div
          style={{
            backgroundImage: `url('/images/buyBlocky/bgBuyBlocky.png')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="relative z-10 pt-[20px] flex flex-col items-center"
        >
          <div className="flex flex-col xl:flex-row xl:justify-center xl:w-full xl:px-[40px] gap-2">
            <div className="xl:w-[750px] xl:order-none order-2 px-[40px] xl:px-[10px]">
              <div className="font-IBM-sans font-bold text-[13px] lg:text-[32px] mb-[6px]">
                $BLOCKY: Your Gateway To GameFi
              </div>
              <p className="font-IBM-sans font-normal text-[10px] lg:text-[24px]">
                As a deflationary GameFi coin with premier gaming utilities,
                owning $BLOCKY places you in the heart of a leading blockchain
                gaming community.
              </p>
              <p className="font-IBM-sans font-normal text-[10px] lg:text-[24px] mb-[6px]">
                Ready to buy $BLOCKY?
              </p>
              <button className="bg-btn-connect-wallet w-[74px] lg:w-[180px] h-[30px] lg:h-[75px] rounded-[70px] font-dongo-regular text-[11px] lg:text-[26px] text-white mb-[10px]">
                Buy now!
              </button>
              <p className="font-IBM-sans font-medium text-[10px] lg:text-[24px] mb-[10px] text-[#221F50]">
                Experience the forefront of blockchain gaming with $Blocky, a
                deflationary GameFi token offering top-tier gaming features.
                Join a vibrant community and immerse yourself in the future of
                gaming.
              </p>
              <div className="font-IBM-sans font-semibold text-[10px] lg:text-[24px] italic">
                Start your journey with $Blocky
              </div>
            </div>
            <div className="flex xl:justify-end xl:items-center xl:order-none order-1 px-[40px] xl:px-[10px]">
              <div className="relative z-30 xl:w-[420px]">
                {/* <img src="/images/buyBlocky/dogCoin.png" alt="bg_2" /> */}
                <img
                  src="/svg/dog-coin.svg"
                  alt="bg_2"
                  className="h-[80px] xl:h-[313px] w-[80px] xl:w-[313px]"
                />
                <div className="absolute z-[-1] top-0 left-[10px] xl:left-[60px]">
                  <img
                    src="/images/buyBlocky/dogCoin.png"
                    alt="bg_2"
                    className="max-w-max h-[80px] xl:h-[313px] w-[80px] xl:w-[313px]"
                  />
                  <div className="absolute z-[-1] top-0 left-[10px] xl:left-[50px]">
                    <img
                      src="/images/buyBlocky/dogCoin.png"
                      alt="bg_2"
                      className="max-w-max h-[80px] xl:h-[313px] w-[80px] xl:w-[313px] "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-2 lg:items-end">
            <div>
              <img
                style={{ bottom: "-1vh" }}
                src="/images/buyBlocky/nft.png"
                alt="bg_2"
                className="w-[200px] lg:w-[650px] h-[163px] lg:h-auto"
              />
            </div>
            <div className="">
              <img
                style={{ bottom: "-1vh" }}
                src="/images/buyBlocky/dog1.png"
                alt="bg_2"
                className="w-[130px] lg:w-[500px] h-[140px] lg:h-[536px]"
              />
            </div>
          </div>
        </div>

        {/* Roadmap */}
        <div className="bg-[#80B1FF] lg:px-[30px] p-[15px] lg:pb-[50px] lg:pl-[50px] pt-[40px] lg:pt-[60px]">
          <div className="flex justify-between">
            <div className="relative pb-6 mb-[15px] lg:mt-[20px]">
              <p className="font-dongo-regular text-dongo text-[32px] lg:text-[54px] -translate-y-1/3 mb-[-50px]">
                Roadmap
              </p>
              <p className="font-dongo-regular text-dongo-before text-[32px] lg:text-[54px] absolute top-0 left-0 z-20 -translate-y-1/3">
                Roadmap
              </p>
            </div>
            <div className="hidden lg:flex items-start">
              <img
                src="/svg/arrow-right.svg"
                alt="left"
                className={`rotate-180 w-12 ${
                  currentSlide === 0
                    ? "disable opacity-50 cursor-default"
                    : "cursor-pointer"
                }`}
                onClick={handlePrev}
              />
              <img
                src="/svg/arrow-right.svg"
                alt="right"
                className={`w-12 ${
                  currentSlide >= getMaxSlides()
                    ? "disable opacity-50 cursor-default"
                    : "cursor-pointer"
                }`}
                onClick={handleNext}
              />
            </div>
          </div>
          <div className="flex gap-4 ">
            <MultipleItems
              sliderRef={sliderRef}
              settings={setting_slide}
              list={roadmap.map((item, i) => (
                <div
                  className="bg-white w-[161px] max-h-[180px] lg:max-h-none lg:h-[500px] overflow-auto  lg:w-[360px] rounded-lg p-[12px] lg:p-6 flex flex-col gap-2.5 rounded-2"
                  key={i}
                >
                  <div className="font-dongo-regular text-[12px] lg:text-xl">
                    {item.phase}
                  </div>
                  <div className="font-IBM-sans uppercase text-[#0067D2] text-[10px] lg:text-base font-bold">
                    {item.title}
                  </div>
                  <div className="flex flex-col gap-[10px]">
                    <div className="flex gap-[10px] flex-col">
                      {item.description.map((des, j) => (
                        <div className="flex items-start gap-2.5" key={j}>
                          {i === 0 ? (
                            <img
                              src="/svg/checkbox.svg"
                              alt="checkbox"
                              className="lg:min-w-[24px] min-w-[14px]"
                            />
                          ) : (
                            <img
                              src="/svg/empty-checkbox.svg"
                              alt="checkbox"
                              className="lg:min-w-[24px] min-w-[14px]"
                            />
                          )}
                          <p
                            className="font-IBM-sans text-[9px] lg:text-[14px]"
                            key={j}
                          >
                            {des.paragraph}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            />
          </div>
        </div>

        {/* Token Allocation */}
        <div
          style={{
            backgroundImage: `url('/images/buyBlocky/bgTokenAllocation.png')`,
            // backgroundPosition: "center",
            // backgroundSize: "cover",
          }}
          className="pt-[30px] lg:pt-[112px] pb-[200px] lg:px-[40px] px-4 flex justify-center flex-col items-center section-token"
        >
          <div>
            <div className="flex justify-center lg:block mb-[18px]">
              <div className="relative">
                <p className="font-dongo-regular text-dongo text-[20px] lg:text-[54px] -translate-y-1/3 mb-[-20px]  lg:mb-[-10px]">
                  Blocky Token Allocation Breakdown
                </p>
                <p className="font-dongo-regular text-dongo-before text-[20px] lg:text-[54px] absolute top-0 left-0 z-20 -translate-y-1/3">
                  Blocky Token Allocation Breakdown
                </p>
              </div>
            </div>
            <div className="lg:flex mb-6 lg:mb-[88px]">
              <div className="flex flex-col gap-2 items-center lg:items-start">
                <div className="flex justify-center lg:hidden block">
                  <img
                    src="/images/buyBlocky/donutChart.png"
                    className="w-[183px] h-[232px]"
                  />
                </div>
                <div className="w-[250px] lg:w-full text-start lg:block gap-[12px]">
                  <div className="lg:w-[700px] mb-[0px] lg:mb-[12px]">
                    <div>
                      <span className="font-IBM-sans font-bold text-[12px] lg:text-2xl ">
                        Presale (Including Seed & Early Birds): 20%
                        (100,000,000,000 BLOCKY)
                      </span>
                    </div>
                    <ul className="font-IBM-sans text-[9px] lg:text-[18px] xl:text-lg max-w-[512px] custom-menu">
                      <li>
                        Tokens allocated for early investors and supporters to
                        provide initial funding and build community momentum.
                      </li>
                      <li>
                        Includes the{" "}
                        <strong>
                          Seed Round, Early Birds Sales, Public Pre-Sale, and
                          Public Sales.
                        </strong>
                      </li>
                    </ul>
                  </div>
                  {token_Allocation.map((item, i) => (
                    <div key={i} className="lg:w-[700px] mb-[0px] lg:mb-[12px]">
                      <div>
                        <span className="font-IBM-sans font-bold text-[12px] lg:text-2xl">
                          {item.title}
                        </span>
                        <span className="font-IBM-sans font-bold text-[12px] lg:text-2xl">
                          {item.statistics}
                        </span>
                      </div>
                      <p className="font-IBM-sans text-[9px] lg:text-[18px] max-w-[512px]">
                        {item.subTitle}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex justify-center lg:block hidden">
                <img
                  src="/images/buyBlocky/donutChart.png"
                  className="w-[512px] xl:h-[647px]"
                />
              </div>
            </div>
            <div className="lg:mb-[90px] mb-[40px] mt-[60px]">
              <div className="relative flex items-center lg:items-start">
                <p className="font-dongo-regular text-dongo text-[28px] lg:text-[54px] -translate-y-1/3">
                  Vesting Schedule
                </p>
                <p className="font-dongo-regular text-dongo-before text-[28px] lg:text-[54px] absolute top-0 lg:left-0 z-20 -translate-y-1/3">
                  Vesting Schedule
                </p>
              </div>
              <div className="flex gap-[18px] flex-col lg:flex-row">
                {vesting_schedule.map((item, i) => (
                  <div
                    className="bg-white rounded-[3px] p-[8px] lg:p-4 lg:w-[200px] xl:p-6 w-[100px] w-full xl:w-[360px]"
                    key={i}
                  >
                    <p className="font-dongo-regular text-[12px] xl:text-xl lg:text-sm">
                      {item.title}
                    </p>
                    <div className="font-IBM-sans text-[9px] lg:text-xs xl:text-sm flex flex-col gap-[4px]">
                      {item.description.map((description, i) => (
                        <span key={i}>{description.subtitle}</span>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:block relative top-[-200px]">
        <div className="absolute w-full">
        <Footer
          backgroundColor={
            "lg:linear-gradient(133deg, rgb(247, 87, 187) 3%, rgb(255, 219, 221) 53%)"
          }
        />
        </div>
      </div>
    </>
  );
};

export default Buy_Blocky;
