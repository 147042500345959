

const BtnMenu = ({ className, type, ...dist }) => {
    return <>
        <button {...dist} className={(type == 'market' ? 'lg:w-36' : 'lg:w-56') + " btn-menu bg-menu hover:bg-none hover:bg-hover-pink w-20 h-6 lg:h-fit text-black font-bold py-3 px-4 rounded-full flex items-center justify-center"}
        >
            <img className={(type == 'market' ? 'lg:w-6' : 'lg:w-fit') + " w-3 mr-2 lg:mr-4"} src="/svg/menu_3.svg" alt="menu" />
            <img className={(type == 'market' ? 'lg:w-16' : 'lg:w-fit') + " w-10"} src="/svg/menu_text.svg" alt="menu_text" />
        </button>
    </>
}

export default BtnMenu;