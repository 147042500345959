import { Fragment, useState } from "react";
// import "../../App.css";
// import Navbar from "../navbar/navbar";
import NFTSlider from "../nft-slider/slider";
import { useNavigate } from "react-router-dom";

import { question } from "../../data/data";
import Footer from "./Footer";

function App({ isNoVideo }) {
  const [isOpenSound, setIsOpenSound] = useState(false);
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const history = useNavigate();
  const handleOpenSound = () => {
    setIsOpenSound(!isOpenSound);
    const artGalleryVideoElm = document.querySelector("#artGallery");
    console.log({ artGalleryVideoElm });
    if (isOpenSound) {
      artGalleryVideoElm.muted = true;
    } else {
      artGalleryVideoElm.muted = false;
    }
  };

  const pushLink = (url) => {
    history(url);
  };

  return (
    <div className="">
      {/* <Navbar /> */}
      <div className="flex flex-col">
        <div
          className="hidden lg:block flex-none lg:h-screen relative"
          style={{
            backgroundImage: `url('/nfts-bg.svg'), url('/splatter-bg.svg')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          {/* video container */}
          <div className="relative h-screen">
            <video
              id="artGallery"
              className="absolute inset-0 object-cover w-full h-full"
              autoPlay
              muted
              loop
              playsInline
            >
              {/* test sound
              <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" /> */}
              {/* <source src="/ArtGallery.mp4" type="video/mp4" /> */}
              {/* <source src="/video/3D_Art Gallery_Compressed_ver2.mp4" type="video/mp4" /> */}
              {!isNoVideo && (
                <source
                  src="/video/3D_Art Gallery_Compressed_ver3.mp4"
                  type="video/mp4"
                />
              )}
              Your browser does not support the video tag.
            </video>

            {/* <div className="absolute top-4 lg:top-14 left-4 lg:left-14 transform">
              <button className="bg-menu hover:bg-none hover:bg-hover-pink w-56 text-black font-bold py-3 px-4 rounded-full flex items-center justify-center"
                onClick={handleOpenMenus}
              >
                <img className="mr-4" src="./svg/menu_3.svg" alt="menu" />
                <img src="./svg/menu_text.svg" alt="menu_text" />
              </button>
            </div> */}

            <div className="absolute bottom-1/2 lg:bottom-1/2 left-4 lg:left-14 transform">
              <img
                className="cursor-pointer w-10 h-10"
                src={isOpenSound ? "./svg/sound.svg" : "./svg/soundMuted.svg"}
                alt="sound"
                onClick={handleOpenSound}
              />
            </div>
            <div className="absolute bottom-4 lg:bottom-14 left-1/2 transform -translate-x-1/2">
              <div className="flex justify-between gap-1.5">
                <button
                  className="button-shadow btn-play hover:bg-yellow-600 font-bold py-2 px-4 rounded-full flex items-center justify-center"
                  onClick={() =>
                    window.open("https://t.me/Blockchaindoggs_bot", "_blank")
                  }
                >
                  <img src="./game.svg" alt="Your Image" />
                  <span className="flex items-center text-white text-Dongo-medium">
                    PLAY NOW
                  </span>
                </button>
                <button
                  className="button-shadow bg-yellow-400 hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded-full flex items-center justify-center"
                  onClick={() =>
                    window.open("https://forms.gle/zVYPtvfXHuXUkVso9", "_blank")
                  }
                >
                  <img src="./money-icon.svg" alt="Your Image" />
                  <span className="flex items-center text-Dongo-medium">
                    WHITELIST!
                  </span>
                </button>
                <button
                  className="button-shadow btn-buyBlocky font-bold py-2 px-4 rounded-full flex items-center justify-center"
                  onClick={() => pushLink("/buy-blocky")}
                >
                  <span className="flex items-center text-white text-Dongo-medium">
                    BUY $BLOCKY!
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* static background container */}
        <div className="relative lg:hidden mb-[50px]">
          <div className="flex flex-col items-center justify-center">
            <img
              className="w-[100vw]"
              src="/svg/home/bgTopMobile2.svg"
              alt="NFTs"
            />
            <div className="lg:bottom-14 lg:mb-0 justify-center flex flex-col pt-[21px]">
              <div className="relative lg:mr-4 m-auto">
                <p className="font-dongo-regular text-dongo-stroke-7 text-[42px] z-9 w-fit">
                  Blockchain Doggs
                </p>
                <p className="font-dongo-regular text-dongo-before text-[42px] absolute top-0 left-0 z-20">
                  Blockchain Doggs
                </p>
              </div>
              <div className="flex justify-between gap-1.5">
                <button
                  className="button-shadow btn-play hover:bg-yellow-600 font-bold py-2 px-4 rounded-full flex items-center justify-center"
                  onClick={() =>
                    window.open("https://t.me/Blockchaindoggs_bot", "_blank")
                  }
                >
                  <img
                    src="./game.svg"
                    alt="Your Image"
                    className="w-[16px] mr-1.5"
                  />
                  <span className="flex items-center text-white font-dongo-regular text-xs mb-[-3px]">
                    PLAY NOW
                  </span>
                </button>
                <button
                  className="button-shadow bg-yellow-400 hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded-full flex items-center justify-center"
                  onClick={() =>
                    window.open("https://forms.gle/zVYPtvfXHuXUkVso9", "_blank")
                  }
                >
                  <img
                    src="./money-icon.svg"
                    alt="Your Image"
                    className="w-[16px] mr-1"
                  />
                  <span className="flex items-center font-dongo-regular text-xs mb-[-3px]">
                    WHITELIST!
                  </span>
                </button>
                <button
                  className="button-shadow btn-buyBlocky font-bold py-2 px-4 rounded-full flex items-center justify-center"
                  onClick={() => pushLink("/buy-blocky")}
                >
                  <span className="flex items-center text-white font-dongo-regular text-xs mb-[-3px]">
                    BUY $BLOCKY!
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex-none  relative"
          style={{
            backgroundImage: `url('/section2-bg.svg')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="flex flex-col items-center justify-center h-full lg:pt-[70px]">
            <div className="relative">
              <p className="text-welcome relative z-10">
                Welcome to Blocky World!
              </p>
              <p className="text-welcome-before absolute top-0 left-0 z-20">
                Welcome to Blocky World!
              </p>
            </div>

            <div className="mt-0 mb-5 lg:mt-[23px] px-[20px] lg:px-[32px]">
              <h1 className="text-center font-IBM-sans text-base lg:text-2xl font-bold ">
                Embark on your epic journey with Gamefi!
              </h1>
              <p className="text-center pt-2 font-IBM-sans text-sm lg:text-2xl xl:max-w-[1230px] leading-5 lg:leading-9">
                Start with the $Blocky token to acquire one of 10,000 unique
                Blockchain Doggs NFTs on the Solana network. Enjoy play-to-earn
                opportunities, unlock exclusive perks, and generate passive
                income. Join the adventure and start earning today!
              </p>
            </div>
            <img
              src="/svg/home/nfts-rows.svg"
              alt="NFTs"
              className="w-full lg:w-3/4 mt-2"
            />
          </div>
        </div>

        <div
          className="flex-none relative lg:pt-8 lg:pb-8"
          style={{
            backgroundImage: `url('/section2-bg.svg')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            // border: '1px solid red'
          }}
        >
          <div className="flex flex-col items-center h-full lg:justify-center">
            <div className="relative">
              <p className="text-meet-dogs">Meet the Doggs</p>
              <p className="text-meet-dogs-before absolute top-0 left-0">
                Meet the Doggs
              </p>
            </div>

            <NFTSlider />
            <div className="w-10/12 lg:w-9/12">
              <p className="text-meet-dogs-section font-IBM-sans text-base font-bold lg:text-2xl mt-[26px] ">
                “Pick your NFT—unleash exclusive art, holder benefits, and
                community!”{" "}
              </p>
              <p className="text-center text-meet-dogs-section-2 mb-5 font-IBM-sans text-sm lg:text-2xl leading-5 lg:leading-9 mt-[8px]">
                Each Dogg is a masterpiece of design and personality, bursting
                with unique characteristics and heartwarming quirks. As you
                explore our diverse collection, you'll encounter everything from
                daring adventurers to serene philosophers, each with their own
                special story to tell. Our Blockchain Doggs are not just
                NFTs—they are companions in the digital age, ready to accompany
                you on every step of your blockchain journey.
              </p>
            </div>

            <div className="flex justify-between gap-1.5 lg:mt-5 lg:mb-12 mb-[26px]">
              <button
                className="button-shadow bg-yellow-400 hover:bg-yellow-600 text-black font-bold py-2 lg:p-[15px] px-4 rounded-full flex items-center justify-center"
                onClick={() =>
                  window.open("https://forms.gle/zVYPtvfXHuXUkVso9", "_blank")
                }
              >
                <img
                  src="./money-icon.svg"
                  alt="money"
                  className="w-[16px] lg:w-[24px] mr-1"
                />
                <span className="flex items-center font-dongo-regular lg:text-2xl text-xs mb-[-3px] lg:mb-[-10px]">
                  WHITELIST!
                </span>
              </button>
              <button
                className="button-shadow btn-buyBlocky font-bold py-2 px-4 rounded-full flex items-center justify-center lg:mb[-10px]"
                onClick={() => pushLink("/buy-blocky")}
              >
                <span className="flex items-center text-white font-dongo-regular lg:text-2xl text-xs mb-[-3px] lg:mb-[-10px]">
                  BUY $BLOCKY!
                </span>
              </button>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundImage: `url('/section-3.svg')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            // border: '1px solid red'
          }}
        >
          <div className="flex flex-col lg:flex-row mx-auto gap-x-20 justify-center px-6 pt-6 lg:pb-6 lg:py-24 ">
            <div className="flex flex-col justify-center items-center text-center m-auto lg:items-start lg:text-start">
              <div className="flex">
                <div className="relative lg:mr-4 mr-2">
                  <p className="font-dongo-regular text-dongo text-[24px] lg:text-[35px] xl:text-[54px] z-9">
                    Doggs Tap:
                  </p>
                  <p className="font-dongo-regular text-dongo-before text-[24px] lg:text-[35px] xl:text-[54px] absolute top-0 left-0 z-20">
                    Doggs Tap:
                  </p>
                </div>
                <div className="relative">
                  <p className="font-dongo-regular text-dongo text-[24px] text-[24px] lg:text-[35px] xl:text-[54px] z-10">
                    Tap2Earn
                  </p>
                  <p className="font-dongo-regular text-[24px] linear-yellow text-[24px] lg:text-[35px] xl:text-[54px] absolute top-0 left-0 z-20">
                    Tap2Earn
                  </p>
                </div>
              </div>
              <div className="font-IBM-sans text-xs lg:text-xl xl:text-2xl mb-2.5 max-w-[245px] lg:max-w-[473px] text-[#221F50]">
                Buy upgrades, complete quests, invite friends and become the
                best
              </div>

              <div className="flex lg:flex-col gap-1">
                <button
                  className="button-shadow btn-play text-black font-bold py-2 px-4 rounded-full flex justify-center max-w-32 mb-2.5 h-[27px] lg:h-[42px]"
                  onClick={() =>
                    window.open("https://t.me/Blockchaindoggs_bot", "_blank")
                  }
                >
                  <img
                    src="./game.svg"
                    alt="Your Image"
                    className="w-[12px] lg:w-[24px] mr-1"
                  />
                  <span className="flex items-center text-white font-dongo-regular text-xs lg:text-2xl mb-[-2px] lg: mb-[-8px]">
                    PLAY
                  </span>
                </button>

                <button
                  className="font-bold py-2 px-4 rounded-full flex  justify-center gap-1 bg-gray max-w-48 h-[27px] lg:h-[42px]"
                  onClick={() => pushLink("/mini-game")}
                >
                  <img
                    src="/svg/info.svg"
                    alt="Your Image"
                    className="w-[12px] lg:w-[24px]"
                  />
                  <span className="flex items-center font-dongo-regular text-xs lg:text-2xl mb-[-2px] lg: mb-[-8px]">
                    More info
                  </span>
                </button>
              </div>
            </div>

            <div className="relative m-auto">
              <img
                src="./game.png"
                alt="game_1"
                className="lg:w-[690px] lg:h-[627px]"
              />
            </div>
          </div>
        </div>

        <div
          className="flex-none relative"
          style={{
            backgroundColor: `#ECE7F7`,
            backgroundSize: "cover",
          }}
        >
          <div className="flex flex-col lg:flex-row lg:w-[80%] m-auto lg:pt-[140px]">
            <div className="flex-none lg:w-3/12 relative">
              {/* Content for the left side */}
              <div className="absolute lg:relative top-[40px] lg:top-0 right-[20px] lg:right-0 z-10 flex flex-col items-center h-full lg:w-fit lg:ml-auto lg:mr-14">
                <div
                  style={{
                    background: "radial-gradient(circle, #B69CFF, #916EED)",
                    display: "inline-block",
                  }}
                  className="rounded-xl mb-5"
                >
                  <img
                    src="/images/homepage/nft-faq.webp"
                    alt="Description"
                    className="m-0 w-[100px] lg:w-full"
                  />
                </div>
                <button
                  className="text-white rounded-full px-3 py-2 lg:px-6 lg:py-3 flex items-center justify-between font-bold text-[10px] lg:text-lg font-dongo-regular "
                  style={{ backgroundColor: "#F1771B" }}
                >
                  <span className="mb-[-5px]">BUY NOW!</span>
                  <img
                    src="money-icon-white.svg" // Replace 'icon-url.png' with the URL of your icon image
                    alt="Description of the icon"
                    className="lg:w-6 lg:h-6 text-white ml-1 w-4"
                  />
                </button>
              </div>
            </div>

            <div
              className="flex-none lg:w-[78%] relative lg:m-auto lg:m-0 space-y-5 md:space-y-0 md:space-x-5"
              style={{
                backgroundSize: "cover",
              }}
            >
              <div className="flex flex-col justify-center w-full h-full px-5 mb-16 lg:mb-28 pt-[60px] lg:pt-0">
                <div className="flex items-end lg:items-start flex-row-reverse lg:flex-row justify-content-start lg:justify">
                  <div className="relative max-w-[137px] lg:max-w-full lg:mr-[16px]">
                    <p className="font-dongo-regular text-dongo text-[26px] lg:text-[54px] z-10">
                      FREQUENTLY ASKED QUESTIONS
                    </p>
                    <p className="font-dongo-regular text-dongo-before absolute text-[26px] lg:text-[54px] top-0 left-0 z-20">
                      FREQUENTLY ASKED QUESTIONS
                    </p>
                  </div>
                  <img
                    src="/svg/arrow-circle-right.svg"
                    alt="arrow-circle"
                    className={`${
                      isOpenCollapse ? "rotate-180" : ""
                    } mb-[10px] mr-[10px] w-[30px] lg:w-[54px] lg:mb-0 lg:mr-0`}
                    onClick={() => setIsOpenCollapse(!isOpenCollapse)}
                  />
                </div>
                {(isOpenCollapse ? question : [question[0]]).map(
                  (question, i) => (
                    <div key={i}>
                      <div className="hidden lg:block font-dongo-regular text-xl">
                        {question.type}
                      </div>
                      {question.question.map((item, i) => (
                        <div className="mb-7">
                          <FAQ
                            defaultShow={true}
                            question={item.ask}
                            text={item.answer}
                            key={i}
                          />
                        </div>
                      ))}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

const FAQ = ({
  defaultShow,
  question = "Why HOLD BLOCKCHAIN DOGGS COINS?",
  text = "",
  isHideBorder,
}) => {
  const [showParagraph, setShowParagraph] = useState(defaultShow);

  const toggleParagraph = () => {
    setShowParagraph(!showParagraph);
  };

  return (
    <Fragment>
      <div className="flex mt-4 w-full">
        <div className="flex items-start w-full flex-col">
          <div className="flex">
            <img
              src="plus-icon.svg" // Replace 'plus-icon.svg' with the URL of your image
              alt="Description of the image"
              className={`w-4 lg:w-6 lg:h-6 mr-3 cursor-pointer transform transition-transform duration-300 ${
                showParagraph ? "rotate-45" : ""
              }`}
              onClick={toggleParagraph}
            />
            <h2
              className="font-IBM-sans cursor-pointer uppercase font-bold text-xs lg:text-base"
              style={{ color: "#6E36E3" }}
              onClick={toggleParagraph}
            >
              {question}
            </h2>
          </div>
          <div className="w-full text-xs lg:text-base">
            {showParagraph && (
              <div
                className={`font-IBM-sans font-normal w-full mt-2 transition-opacity duration-300 text-[9px] lg:text-sm ${
                  showParagraph ? "opacity-100" : "opacity-0 h-0"
                }`}
              >
                {/* Tokens distributed as rewards must be whitelisted onchain. Fill
              this form if you want to whitelist a token. Tokens distributed as
              rewards must be whitelisted onchain. Fill this form if you want to
              whitelist a token. */}
                <p dangerouslySetInnerHTML={{ __html: text }}></p>
              </div>
            )}
          </div>
        </div>
      </div>
      {!isHideBorder && (
        <div className="border-b w-full my-2 border-gray-800 opacity-10"></div>
      )}
    </Fragment>
  );
};
export default App;
