import { useEffect } from 'react';
import {
  MiniGameDivider,
  MiniGameSection1,
  MiniGameSection2,
  MiniGameSection3,
  MiniGameSection4,
} from '../mini-game';

function MiniGamePage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <MiniGameSection1 />

      <MiniGameSection2 />
      <MiniGameDivider />

      <MiniGameSection3 />
      <MiniGameDivider />

      <MiniGameSection4 />
      <MiniGameDivider isBottom />
    </div>
  );
}

export default MiniGamePage;
