import MiniGame8 from '../../assets/images/mini-game-8.png';
import MiniGame9 from '../../assets/images/mini-game-9.png';

export function MiniGameSection2() {
  return (
    <div className={`p-4 md:px-6 md:py-10 flex flex-col items-center gap-4 md:gap-10 relative`}>
      <div className="flex flex-col md:flex-row justify-center items-center gap-4 md:gap-10">
        <div className="relative text-[25px] md:text-[64px] text-center md:text-left leading-none -tracking-[0.03em]">
          <div className="font-dongo-regular bg-gradient-1">
            Earn bonus points <br /> defeating the bosses!
          </div>
          <div className="font-dongo-outline text-[#221F50] absolute top-0">
            Earn bonus points <br /> defeating the bosses!
          </div>
        </div>

        <p className="text-xs md:text-2xl max-w-lg text-center md:text-left">
          Boost your gains by participating in our Falling Object Games! Complete quests for
          rewards, crack daily puzzles, and power up your Doggs to take over the blocky world
        </p>
      </div>

      <img src={MiniGame9} alt="" />

      <div className={`bg-[url(${MiniGame8})] bg-cover bg-center inset-0 absolute blur-xl -z-10`} />
    </div>
  );
}
